import axios from "axios";

// const API_URL = "http://localhost:8080/api"
// const API_URL = "https://besuto12-api.et.r.appspot.com/api"

const API_URL = "https://besuto-api-sw9kc.ondigitalocean.app/api"


const apiAxios = axios.create({
    baseURL: `${API_URL}`,
});

apiAxios.interceptors.request.use(
    (config) => {
        // attach accessToken
        config.headers = {
            ...config.headers,
            "Content-Type": "application/json"
        };
        const accessToken = localStorage.getItem("access_token");

        if (accessToken) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`
            };
        }
        return config;
    },
    error => {
        // Do something with request error
        return Promise.reject(error);
    }
);

export const upload = `${API_URL}/upload`

export function login(data) {
    return apiAxios.post('/auth/login', data)
}

export function validateAuth() {
    return apiAxios.get('/auth/validate')
}

export function getContent() {
    return apiAxios.get('/content')
}

export function updateContent(data) {
    return apiAxios.post('/content', data)
}


export function getStore() {
    return apiAxios.get('/store')
}

export function createStore(data) {
    return apiAxios.post('/store', data)
}

export function updateStore(id, data) {
    return apiAxios.put(`/store/${id}`, data)
}

export function destroyStore(id) {
    return apiAxios.delete(`/store/${id}`)
}

export function getFaq() {
    return apiAxios.get('/faq')
}

export function createFaq(data) {
    return apiAxios.post('/faq', data)
}

export function updateFaq(id, data) {
    return apiAxios.put(`/faq/${id}`, data)
}

export function destroyFaq(id) {
    return apiAxios.delete(`/faq/${id}`)
}