import React, { useState, useEffect } from "react";
import { Form, Input, Button, Divider, message, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import * as api from "../../api";
import Layout from "../../components/admin/layout";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 8 },
};

let initImage = {
  product_information_background_desktop: undefined,
  product_information_background_mobile: undefined,
  product_information_origin_background_th: undefined,
  product_information_origin_background_en: undefined,
  product_information_promote_background_desktop_th: undefined,
  product_information_promote_background_desktop_en: undefined,
  product_information_promote_background_mobile_th: undefined,
  product_information_promote_background_mobile_en: undefined,
};

const ProductInformation = () => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [content, setContent] = useState(null);
  const [image, setImage] = useState(initImage);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      const {
        data: { data },
      } = await api.getContent();
      setContent(data.content);
      setImage({
        product_information_background_desktop:
          data.content["product_information_background_desktop"],
        product_information_background_mobile:
          data.content["product_information_background_mobile"],
        product_information_origin_background_th:
          data.content["product_information_origin_background_th"],
        product_information_origin_background_en:
          data.content["product_information_origin_background_en"],
        product_information_promote_background_desktop_th:
          data.content["product_information_promote_background_desktop_th"],
        product_information_promote_background_desktop_en:
          data.content["product_information_promote_background_desktop_en"],
        product_information_promote_background_mobile_th:
          data.content["product_information_promote_background_mobile_th"],
        product_information_promote_background_mobile_en:
          data.content["product_information_promote_background_mobile_en"],
      });
      form.setFieldsValue(data.content);
    } catch (error) {
      console.log("Error while call init()", error);
    }
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  }

  function handleChange(info, key) {
    if (info.file.status === "done") {
      setImage((prev) => ({ ...prev, [key]: info.file.response.data.url }));
    }
  }

  const onFinish = (values) => {
    update({
      ...values,
      ...image

    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  async function update(data) {
    try {
      setIsSubmitting(true)
      const res = await api.updateContent(data)
      message.success('บันทึกเรียบร้อยแล้ว');
    } catch (error) {
      message.success('เกิดข้อผิดพลา ดกรุณาลองใหม่อีกครั้ง');
      console.log("Error while call error()", error);
    }
    setIsSubmitting(false)
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Layout>
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="หัวข้อ (ไทย)" name="product_information_title_th">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="หัวข้อ (อังกฤษ)" name="product_information_title_en">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="ข้อความ (ไทย)"
          name="product_information_description_th"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="ข้อความ (อังกฤษ)"
          name="product_information_description_en"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="ข้อความของปุ่ม (ไทย)"
          name="product_information_button_th"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ข้อความของปุ่ม (อังกฤษ)"
          name="product_information_button_en"
        >
          <Input />
        </Form.Item>
        <Form.Item label="ลิ้งค์ของปุ่ม" name="product_information_link_button">
          <Input />
        </Form.Item>
        <Form.Item
          label="พื้นหลัง Desktop (ไทย)"
          name="product_information_background_desktop"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "product_information_background_desktop")
            }
          >
            {image.product_information_background_desktop ? (
              <img
                src={image.product_information_background_desktop}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="พื้นหลัง Mobile (ไทย)"
          name="product_information_background_mobile"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "product_information_background_mobile")
            }
          >
            {image.product_information_background_mobile ? (
              <img
                src={image.product_information_background_mobile}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Divider />
        <Form.Item
          label="หัวข้อ (ไทย)"
          name="product_information_origin_title_th"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="หัวข้อ (อังกฤษ)"
          name="product_information_origin_title_en"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="ข้อความ (ไทย)"
          name="product_information_origin_description_th"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="ข้อความ (อังกฤษ)"
          name="product_information_origin_description_en"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="รูป (ไทย)"
          name="product_information_origin_background_th"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "product_information_origin_background_th")
            }
          >
            {image.product_information_origin_background_th ? (
              <img
                src={image.product_information_origin_background_th}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="รูป (อังกฤษ)"
          name="product_information_origin_background_en"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "product_information_origin_background_en")
            }
          >
            {image.product_information_origin_background_en ? (
              <img
                src={image.product_information_origin_background_en}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Divider />
        <Form.Item
          label="ข้อความด้านซ้าย(ไทย)"
          name="product_information_promote_left_th"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="ข้อความด้านซ้าย (อังกฤษ)"
          name="product_information_promote_left_en"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="ข้อความด้านขวา (ไทย)"
          name="product_information_promote_right_th"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="ข้อความด้านขวา (อังกฤษ)"
          name="product_information_promote_right_en"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="พื้นหลังบนคอมพิวเตอร์ (ไทย)"
          name="product_information_promote_background_desktop_th"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(
                info,
                "product_information_promote_background_desktop_th"
              )
            }
          >
            {image.product_information_promote_background_desktop_th ? (
              <img
                src={image.product_information_promote_background_desktop_th}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="พื้นหลังบนคอมพิวเตอร์ (อังกฤษ)"
          name="product_information_promote_background_desktop_en"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(
                info,
                "product_information_promote_background_desktop_en"
              )
            }
          >
            {image.product_information_promote_background_desktop_en ? (
              <img
                src={image.product_information_promote_background_desktop_en}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="พื้นหลังบนมือถือ (ไทย)"
          name="product_information_promote_background_mobile_th"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(
                info,
                "product_information_promote_background_mobile_th"
              )
            }
          >
            {image.product_information_promote_background_mobile_th ? (
              <img
                src={image.product_information_promote_background_mobile_th}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="พื้นหลังบนมือถือ(อังกฤษ)"
          name="product_information_promote_background_mobile_en"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(
                info,
                "product_information_promote_background_mobile_en"
              )
            }
          >
            {image.product_information_promote_background_mobile_en ? (
              <img
                src={image.product_information_promote_background_mobile_en}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType="button" onClick={isSubmitting}>
            ยกเลิก
          </Button>
          <Button type="primary" htmlType="submit" onClick={isSubmitting}>
            บันทึก
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default ProductInformation;
