import React, { useState, useEffect } from "react";
import { Form, Input, Button, Divider, message, Upload } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import * as api from "../../api";
import Layout from "../../components/admin/layout";

let initImage = {
  award_image_left: undefined,
  award_image_right: undefined,
  award_file: undefined,
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 8 },
};

const Awards = () => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [content, setContent] = useState([]);
  const [image, setImage] = useState(initImage);
  const [file, setFile] = useState(undefined);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      const {
        data: { data },
      } = await api.getContent();
      setContent(data.content);
      setImage({
        award_image_left: data.content["award_image_left"],
        award_image_right: data.content["award_image_right"],
        award_file: data.content["award_file"],
      });
      form.setFieldsValue(data.content);
    } catch (error) {
      console.log("Error while call init()", error);
    }
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  }

  function handleChange(info, key) {
    if (info.file.status === "done") {
      setImage((prev) => ({ ...prev, [key]: info.file.response.data.url }));
    }
  }

  const onFinish = (values) => {
    update({
      ...values,
      ...image
    })
  };


  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  async function update(data) {
    try {
      setIsSubmitting(true)
      const res = await api.updateContent(data)
      message.success('บันทึกเรียบร้อยแล้ว');
    } catch (error) {
      message.success('เกิดข้อผิดพลา ดกรุณาลองใหม่อีกครั้ง');
      console.log("Error while call error()", error);
    }
    setIsSubmitting(false)
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Layout>
      <Form
        form={form}
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="ข้อความ (ไทย)" name="award_title_th">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="ข้อความ (อังกฤษ)" name="award_title_en">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="ข้อความลิ้งค์ (ไทย)" name="award_link_th">
          <Input />
        </Form.Item>
        <Form.Item label="ข้อความลิ้งค์ (อังกฤษ)" name="award_link_en">
          <Input />
        </Form.Item>
        <Form.Item label="ไฟล์" name="award_file">
          {/* <Upload name="file" action={api.upload} >
            <Button icon={<UploadOutlined />}>
              อัพโหลด
            </Button>
          </Upload> */}
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) => handleChange(info, "award_file")}
          >
            {image.award_file ? (
              <img
                src={image.award_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item label="รูปด้านซ้าย" name="award_image_left">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) => handleChange(info, "award_image_left")}
          >
            {image.award_image_left ? (
              <img
                src={image.award_image_left}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item label="รูปด้านขวา" name="award_image_right">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) => handleChange(info, "award_image_right")}
          >
            {image.award_image_right ? (
              <img
                src={image.award_image_right}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button htmlType="button">ยกเลิก</Button>
          <Button type="primary" htmlType="submit">
            บันทึก
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default Awards;
