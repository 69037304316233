import React, { useState, useEffect } from "react";
import { Form, Input, Button, Table, Space, Popconfirm, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import * as api from "../../api";
import Layout from "../../components/admin/layout";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const Faqs = () => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShownForm, setIsShownForm] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await api.getFaq();
      setFaq(data.faq);
    } catch (error) {
      console.log("Error while call init()", error);
    }
    setIsLoading(false);
  }

  async function create(data) {
    try {
      setIsSubmitting(true);
      const res = await api.createFaq(data);
      init();
      closeForm();
      message.success('บันทึกเรียบร้อยแล้ว');
    } catch (error) {
      message.success('เกิดข้อผิดพลา ดกรุณาลองใหม่อีกครั้ง');
      console.log("Error while call create()", error);
    }
    setIsSubmitting(false);
  }

  async function update(data) {
    try {
      setIsSubmitting(true);
      const res = await api.updateFaq(selectedFaq.id, data);
      init();
      closeForm();
      message.success('บันทึกเรียบร้อยแล้ว');
    } catch (error) {
      message.success('เกิดข้อผิดพลา ดกรุณาลองใหม่อีกครั้ง');
      console.log("Error while call update()", error);
    }
    setIsSubmitting(false);
  }

  async function destroy(id) {
    try {
      setIsSubmitting(true);
      const res = await api.destroyFaq(id);
      init();
    } catch (error) {
      console.log("Error while call destroy()", error);
    }
    setIsSubmitting(false);
  }

  const onFinish = (values) => {
    if (selectedFaq) {
      update(values);
    } else {
      create(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function selectFaq(data) {
    setSelectedFaq(data);
    form.setFieldsValue(data);
  }

  function closeForm() {
    setIsShownForm(false);
    setSelectedFaq(null);
    form.resetFields();
  }

  const columns = [
    {
      title: "คำถาม (ไทย)",
      dataIndex: "question_th",
      key: "question_th",
      width: 240,
    },
    {
      title: "คำถาม (อังกฤษ)",
      dataIndex: "question_en",
      key: "question_en",
      width: 240,
    },
    {
      title: "คำตอบ (ไทย)",
      dataIndex: "answer_th",
      key: "answer_th",
      width: 240,
    },
    {
      title: "คำตอบ (อังกฤษ)",
      dataIndex: "answer_en",
      key: "answer_en",
      width: 240,
    },
    {
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => selectFaq(record)}>แก้ไข</a>
          <Popconfirm
            okText="ใช่"
            cancelText="ไม่ใช่"
            title="คุณต้องการที่จะลบใช่หรือไม่?"
            onConfirm={() => destroy(record.id)}
          >
            <a>ลบ</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Layout>
      <div style={{ textAlign: "right", marginBottom: "8px" }}>
        <Button icon={<PlusOutlined />} onClick={() => setIsShownForm(true)}>
          เพิ่ม
        </Button>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={faq}
        scroll={{ x: "max-content" }}
        loading={isLoading}
      />
      <Modal
        footer={null}
        visible={isShownForm || selectedFaq}
        onCancel={closeForm}
      >
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="คำถาม (ไทย)" name="question_th">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="คำถาม (อังกฤษ)" name="question_en">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="คำตอบ (ไทย)" name="answer_th">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="คำตอบ (อังกฤษ)" name="answer_en">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              htmlType="button"
              onClick={closeForm}
              disabled={isSubmitting}
            >
              ยกเลิก
            </Button>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              บันทึก
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default Faqs;
