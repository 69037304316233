import React, { useState, useEffect } from "react";
import { Form, Input, Button, Divider, message } from "antd";
import { useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import * as api from "../../api";

const Login = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const onFinish = (values) => {
    login(values);
  };

  useEffect(() => {
    validate();
  }, []);

  function validate() {
    const accessToken = Cookie.get("access_token");
    if (accessToken) {
      history.push("/admin");
    }
  }

  async function login(data) {
    try {
      setIsSubmitting(true);
      const res = await api.login(data);
      Cookie.set("access_token", res.data.data.token);
      history.push("/admin");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง";
      message.error(errorMessage);
      console.log("Error while call login()", error);
    }
    setIsSubmitting(false);
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="login">
      <Form
        className="login__form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="login__header">
          <img width="128" src="/images/logo-2.png" />
          <Divider type="vertical" />
          <div className="login__title">ADMIN</div>
        </div>
        <Form.Item
          name="username"
          rules={[{ required: true, message: "กรุณากรอกชื่อผู้ใช้!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: "กรุณากรอกรหัสผ่าน!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={isSubmitting}>
            เข้าสู่ระบบ
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
