import React, { useState, useEffect } from "react";
import { Form, Input, Button, Divider, message, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import * as api from "../../api";
import Layout from "../../components/admin/layout";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 8 },
};

let initImage = {
  contact_background_desktop: undefined,
  contact_background_mobile: undefined,
};

const ProductInformation = () => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [content, setContent] = useState([]);
  const [image, setImage] = useState(initImage);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      const {
        data: { data },
      } = await api.getContent();
      setContent(data.content);
      setImage({
        contact_background_desktop:
          data.content["contact_background_desktop"],
          contact_background_mobile:
          data.content["contact_background_mobile"],
      });
      form.setFieldsValue(data.content);
    } catch (error) {
      console.log("Error while call init()", error);
    }
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  }

  function handleChange(info, key) {
    if (info.file.status === "done") {
      setImage((prev) => ({ ...prev, [key]: info.file.response.data.url }));
    }
  }

  const onFinish = (values) => {
    update({
      ...values,
      ...image
    })
  };


  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  async function update(data) {
    try {
      setIsSubmitting(true)
      const res = await api.updateContent(data)
      message.success('บันทึกเรียบร้อยแล้ว');
    } catch (error) {
      message.success('เกิดข้อผิดพลา ดกรุณาลองใหม่อีกครั้ง');
      console.log("Error while call error()", error);
    }
    setIsSubmitting(false)
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );


  return (
    <Layout>
      <Form
        form={form}
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="ชื่อบริษัท (ไทย)" name="company_name_th">
          <Input />
        </Form.Item>
        <Form.Item label="ชื่อบริษัท (อังกฤษ)" name="company_name_en">
          <Input />
        </Form.Item>
        <Form.Item label="ที่อยู่ (ไทย)" name="company_address_th">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="ที่อยู่ (อังกฤษ)" name="company_address_en">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="เบอร์​โทรศัพท์" name="phone_number">
          <Input />
        </Form.Item>
        <Form.Item label="อีเมล" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Facebook" name="facebook">
          <Input />
        </Form.Item>
        <Form.Item label="Line" name="line">
          <Input />
        </Form.Item>
        <Form.Item
          label="รูปพื้นหลังบนคอมพิวเตอร์"
          name="contact_background_desktop"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "contact_background_desktop")
            }
          >
            {image.contact_background_desktop ? (
              <img
                src={image.contact_background_desktop}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="รูปพื้นหลังบนมือถือ"
          name="contact_background_mobile"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "contact_background_mobile")
            }
          >
            {image.contact_background_mobile ? (
              <img
                src={image.contact_background_mobile}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button htmlType="button">ยกเลิก</Button>
          <Button type="primary" htmlType="submit">
            บันทึก
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default ProductInformation;
