import React from "react";
import cn from "classnames";

const Pagination = ({ page, active, onChange }) => {
  return (
    <ul className="pagination">
      {Array(page)
        .fill(0)
        .map((x, i) => (
          <li className={cn({ "active": active === i + 1})} onClick={() => onChange(i + 1)}>{i + 1}</li>
        ))}
    </ul>
  );
};

export default Pagination;
