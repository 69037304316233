import cn from "classnames";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Slider from "react-slick";
import * as api from "../api";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import Pagiantion from "../components/pagination";
import BesutoLogo from "../resources/images/logo-2.png";
function distance(lat1, lon1, lat2, lon2) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515 * 1.609344;
    return dist;
  }
}

const Pin = ({ text }) => (
  <div>
    <i className="fas fa-map-marker-alt fa-3x" style={{ color: "salmon" }} />
  </div>
);

function App() {
  const slider = useRef();
  const sliderMenu = useRef();
  const { t, i18n } = useTranslation();
  const [otherPropertyIndex, setOtherPropertyIndex] = useState(0);
  const [answerIndex, setAnswertIndex] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [center, setCenter] = useState({
    lat: 13.7563,
    lng: 100.5018,
  });
  const [zoom, setZoom] = useState(15);
  const settings = {
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      sliderMenu.current.slickGoTo(next);
      setOtherPropertyIndex(next);
    },
  };

  const [selctedLocation, setSelctedLocation] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [activeMenu, setActiveMenu] = useState("product_information");
  const [content, setContent] = useState(null);
  const [faq, setFaq] = useState([]);
  const [store, setStore] = useState([]);
  const [filteredStore, setFilteredStore] = useState([]);

  const productInformationContainer = useRef(null);
  const checkpointsContainer = useRef(null);
  const qualificationContainer = useRef(null);
  const howToUseContainer = useRef(null);
  const faqsContainer = useRef(null);
  const awardsContainer = useRef(null);
  const storesContainer = useRef(null);
  const aboutUsContainer = useRef(null);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      const [contentRes, faqRes, storeRes] = await Promise.all([
        api.getContent(),
        api.getFaq(),
        api.getStore(),
      ]);
      setContent(contentRes.data.data.content);
      setFaq(faqRes.data.data.faq);
      setStore(storeRes.data.data.store);
      setFilteredStore(storeRes.data.data.store);
    } catch (error) {
      console.log("Error while call init()", error);
    }
  }

  const settingsPros = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settingsInstruction = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  const settingsPropertyMenu = {
    dots: false,
    arrows: false,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      slider.current.slickGoTo(next);
      setOtherPropertyIndex(next);
    },
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (productInformationContainer.current) {
      observer.observe(productInformationContainer.current);
    }
    if (checkpointsContainer.current) {
      observer.observe(checkpointsContainer.current);
    }
    if (qualificationContainer.current) {
      observer.observe(qualificationContainer.current);
    }
    if (howToUseContainer.current) {
      observer.observe(howToUseContainer.current);
    }
    if (faqsContainer.current) {
      observer.observe(faqsContainer.current);
    }
    if (awardsContainer.current) {
      observer.observe(awardsContainer.current);
    }
    if (storesContainer.current) {
      observer.observe(storesContainer.current);
    }
    if (aboutUsContainer.current) {
      observer.observe(aboutUsContainer.current);
    }
  }, []);

  function handleObserver(entities) {
    entities.forEach((target) => {
      if (target.isIntersecting) {
        setActiveMenu(target.target.parentNode.id);
      }
    });
  }

  useEffect(() => {
    if (!keyword) {
      setFilteredStore(store);
    } else {
      const pattern = String.raw`${keyword}`;
      const regx = RegExp(pattern, "g");
      setFilteredStore(
        store.filter(
          (x) =>
            x[`name_${i18n.language}`].match(regx) ||
            x[`address_${i18n.language}`].match(regx)
        )
      );
    }
  }, [keyword]);

  function handleSearch(e) {
    const value = e.target.value;
    setPage(1);
    setKeyword(value);
  }

  function getLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const x = store.map((x) => ({
          ...x,
          distance: distance(
            parseFloat(position.coords.latitude),
            parseFloat(position.coords.longitude),
            x.latitude,
            x.longitude
          ),
        }));
        const nearestStore = x.reduce((prev, curr) =>
          prev.distance < curr.distance ? prev : curr
        );
        setCenter({
          lat: nearestStore.latitude,
          lng: nearestStore.longitude,
        });
        selectLocation(nearestStore.code);
      },
      (error) => {
        console.log(error.message);
      }
    );
  }

  function handleChangePage(page) {
    setPage(page);
  }

  function handleChangeAnswer(index) {
    setAnswertIndex((prev) => (prev === index ? 0 : index));
  }

  function selectLocation(code) {
    const x = store.find((x) => x.code === code);
    setCenter({
      lat: x.latitude,
      lng: x.longitude,
    });
    setSelctedLocation(x);
  }

  function renderLocation() {
    const x = filteredStore.slice((page - 1) * 10, page * 10);
    return x;
  }

  function handleChangeSlide(index) {
    slider.current.slickGoTo(index);
  }

  function handleBoundsChange(center) {
    setCenter(center);
  }

  function renderContent(key) {
    if (!content) return null;
    let x = content[`${key}_${i18n.language}`];
    if (!x) {
      x = content[`${key}`];
    }
    return x;
  }

  return (
    <div className="app">
      <a className="social--line" href={renderContent("line")} target="_blank">
        <img src="/images/line.png" />
      </a>
      <Navbar
        content={content}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
      />
      {selectedDocument && (
        <Lightbox
          mainSrc={selectedDocument[selectedDocumentIndex]}
          nextSrc={
            selectedDocument[
              (selectedDocumentIndex + 1) % selectedDocument.length
            ]
          }
          prevSrc={
            selectedDocument[
              (selectedDocumentIndex + selectedDocument.length - 1) %
                selectedDocument.length
            ]
          }
          onCloseRequest={() => {
            setSelectedDocument(null);
            setSelectedDocumentIndex(0);
          }}
          onMovePrevRequest={() =>
            setSelectedDocumentIndex(
              (selectedDocumentIndex + selectedDocument.length - 1) %
                selectedDocument.length
            )
          }
          onMoveNextRequest={() =>
            setSelectedDocumentIndex(
              (selectedDocumentIndex + 1) % selectedDocument.length
            )
          }
        />
      )}
      <div id="product_information" className="banner layout">
        <div ref={productInformationContainer} />

        <div className="banner__content">
          <div className="container content">
            {/* <div className="badge">{t("new")}</div> */}
            {i18n.language === "en" ? (
              <>
                <img
                  className="covid-badge covid-badge--desktop"
                  src="/images/covid19-badge.png"
                  alt="covid 19 badge"
                />
                <img
                  className="covid-badge covid-badge--mobile"
                  src="/images/covid19-badge.png"
                  alt="covid 19 badge"
                />
              </>
            ) : (
              <>
                <img
                  className="covid-badge covid-badge--desktop"
                  src="/images/covid19-badge.png"
                  alt="covid 19 badge"
                />
                <img
                  className="covid-badge covid-badge--mobile"
                  src="/images/covid19-badge.png"
                  alt="covid 19 badge"
                />
              </>
            )}
            <div className="title title--primary banner__title">
              <div className="content-text">
                {renderContent("product_information_title")}
              </div>
            </div>
            <div className="meta">
              <div className="content-text">
                {renderContent("product_information_description")}
              </div>
            </div>
            <a
              href={renderContent("product_information_link_button")}
              target="_blank"
            >
              <button className="button">
                {renderContent("product_information_button")}
              </button>
            </a>
          </div>
        </div>
        <img
          className="banner__image"
          src={renderContent("product_information_background_desktop")}
        />
        <img
          className="banner__image--mobile"
          src={renderContent("product_information_background_mobile")}
        />
      </div>

      <div className="about-established layout">
        <div className="container layout--half">
          <div className="content">
            <div className="title--primary">
              <span className="quote" />
              {t("about established 1")}
            </div>
            <div className="meta">
              {renderContent("product_information_origin_title")}
            </div>
            <div>{renderContent("product_information_origin_description")}</div>
          </div>
          <img
            width="100%"
            src={renderContent("product_information_origin_background_th")}
          />
        </div>
      </div>

      <div
        className={cn("about layout", { "about--en": i18n.language === "en" })}
      >
        <div className="about__box">
          <img
            className="about__image"
            src={renderContent(
              "product_information_promote_background_desktop"
            )}
          />

          <div className="about__content">
            <div>{renderContent("product_information_promote_left")}</div>
            <div>{renderContent("product_information_promote_right")}</div>
          </div>
        </div>
        <img
          className="about__image--mobile"
          src={renderContent("product_information_promote_background_mobile")}
        />
      </div>

      <div
        id="checkpoints"
        className="property layout"
        style={{
          backgroundImage: `url(${renderContent("checkpoint_background")})`,
        }}
      >
        <div ref={checkpointsContainer} data-id="checkpoints" />
        <div className="container">
          <div className="title">{t("pros 1")}</div>
          <div className="property__list">
            <div
              className={cn("property__item", {
                "property__item--en": i18n.language === "en",
              })}
            >
              <div className="property__number">1</div>
              <div className="property__content">
                <img
                  className="property__image"
                  src={renderContent("checkpoint_image_1")}
                />
                <div className="property__text">
                  {renderContent("checkpoint_title_1")}
                </div>
              </div>
            </div>
            <div
              className={cn("property__item", {
                "property__item--en": i18n.language === "en",
              })}
            >
              <div className="property__number">2</div>
              <div className="property__content">
                <img
                  className="property__image"
                  src={renderContent("checkpoint_image_2")}
                />
                <div className="property__text">
                  {renderContent("checkpoint_title_2")}
                </div>
              </div>
            </div>
            <div
              className={cn("property__item", {
                "property__item--en": i18n.language === "en",
              })}
            >
              <div className="property__number">3</div>
              <div className="property__content">
                <img
                  className="property__image"
                  src={renderContent("checkpoint_image_3")}
                />
                <div className="property__text">
                  {renderContent("checkpoint_title_3")}
                </div>
              </div>
            </div>
            <div
              className={cn("property__item", {
                "property__item--en": i18n.language === "en",
              })}
            >
              <div className="property__number">4</div>
              <div className="property__content">
                <img
                  className="property__image"
                  src={renderContent("checkpoint_image_4")}
                />
                <div className="property__text">
                  {renderContent("checkpoint_title_4")}
                </div>
              </div>
            </div>
          </div>

          <div className="property__slider">
            <Slider {...settingsPros}>
              <div
                className={cn("property__item", {
                  "property__item--en": i18n.language === "en",
                })}
              >
                <div className="property__number">1</div>
                <div className="property__content">
                  <img
                    className="property__image"
                    src={renderContent("checkpoint_image_1")}
                  />
                  <div className="property__text">
                    {renderContent("checkpoint_title_1")}
                  </div>
                </div>
              </div>
              <div
                className={cn("property__item", {
                  "property__item--en": i18n.language === "en",
                })}
              >
                <div className="property__number">2</div>
                <div className="property__content">
                  <img
                    className="property__image"
                    src={renderContent("checkpoint_image_2")}
                  />
                  <div className="property__text">
                    {renderContent("checkpoint_title_2")}
                  </div>
                </div>
              </div>
              <div
                className={cn("property__item", {
                  "property__item--en": i18n.language === "en",
                })}
              >
                <div className="property__number">3</div>
                <div className="property__content">
                  <img
                    className="property__image"
                    src={renderContent("checkpoint_image_3")}
                  />
                  <div className="property__text">
                    {renderContent("checkpoint_title_3")}
                  </div>
                </div>
              </div>
              <div
                className={cn("property__item", {
                  "property__item--en": i18n.language === "en",
                })}
              >
                <div className="property__number">4</div>
                <div className="property__content">
                  <img
                    className="property__image"
                    src={renderContent("checkpoint_image_4")}
                  />
                  <div className="property__text">
                    {renderContent("checkpoint_title_4")}
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="property-minor layout">
        <div className="container layout--half">
          <img
            width="100%"
            src={renderContent("checkpoints_testing_1_image")}
          />
          <div className="content">
            <div className="property__header">
              <div className="property__number">1</div>
              <div className="title">
                {renderContent("checkpoints_testing_1_title")}
              </div>
            </div>
            <div className="divider" />
            <div>{renderContent("checkpoints_testing_1_description")}</div>
            <div
              className="link"
              onClick={() =>
                setSelectedDocument([
                  renderContent("checkpoints_testing_1_file"),
                ])
              }
            >
              <i className="far fa-file-search"></i>
              <div>{renderContent("checkpoints_testing_1_link")}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="property-minor layout"
        style={{
          backgroundColor: "#E8F0EA",
        }}
      >
        <div className="container">
          <div className="container layout--half">
            <img
              className="property-minor__image--mobile"
              width="100%"
              src={renderContent("checkpoints_testing_2_image")}
            />
            <div className="content">
              <div className="property__header">
                <div className="property__number">2</div>
                <div className="title">
                  {renderContent("checkpoints_testing_2_title")}
                </div>
              </div>
              <div className="divider" />
              <div className="content-text">
                {renderContent("checkpoints_testing_2_description")}
              </div>
              <div className="property__detail">
                {renderContent("checkpoints_testing_2_subtitle")}
              </div>
              <div>
                <div className="property__detail-content content-text">
                  {renderContent("checkpoints_testing_2_meta")}
                </div>
              </div>
              <div className="content" style={{ marginTop: "32px" }}>
                <div className="property__document-a">
                  <a
                    href={renderContent("checkpoints_testing_2_1_1_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img
                      src={renderContent("checkpoints_testing_2_1_1_file")}
                    />
                  </a>
                  <a
                    href={renderContent("checkpoints_testing_2_1_2_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img
                      src={renderContent("checkpoints_testing_2_1_2_file")}
                    />
                  </a>
                  <a
                    href={renderContent("checkpoints_testing_2_1_3_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img
                      src={renderContent("checkpoints_testing_2_1_3_file")}
                    />
                  </a>
                  <a
                    href={renderContent("checkpoints_testing_2_1_4_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img
                      src={renderContent("checkpoints_testing_2_1_4_file")}
                    />
                  </a>
                  <a
                    href={renderContent("checkpoints_testing_2_1_5_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img
                      src={renderContent("checkpoints_testing_2_1_5_file")}
                    />
                  </a>
                  <a
                    href={renderContent("checkpoints_testing_2_1_6_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img
                      src={renderContent("checkpoints_testing_2_1_6_file")}
                    />
                  </a>
                  <a
                    href={renderContent("checkpoints_testing_2_1_7_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img
                      src={renderContent("checkpoints_testing_2_1_7_file")}
                    />
                  </a>
                </div>
                <div
                  className="link"
                  onClick={() =>
                    setSelectedDocument([
                      renderContent("checkpoints_testing_2_1_1_file"),
                      renderContent("checkpoints_testing_2_1_2_file"),
                      renderContent("checkpoints_testing_2_1_3_file"),
                      renderContent("checkpoints_testing_2_1_4_file"),
                      renderContent("checkpoints_testing_2_1_5_file"),
                      renderContent("checkpoints_testing_2_1_6_file"),
                      renderContent("checkpoints_testing_2_1_7_file")
                    ])
                  }
                >
                  <i className="far fa-file-search"></i>
                  <div>{renderContent("checkpoints_testing_2_1_link")}</div>
                </div>
              </div>

              <div className="content" style={{ marginTop: "32px" }}>
                <div className="property__document-a">
                  <a
                    href={renderContent("checkpoints_testing_2_2_1_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img
                      src={renderContent("checkpoints_testing_2_2_1_file")}
                    />
                  </a>
                  <a
                    href={renderContent("checkpoints_testing_2_2_2_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img
                      src={renderContent("checkpoints_testing_2_2_2_file")}
                    />
                  </a>
                </div>
                <div
                  className="link"
                  onClick={() =>
                    setSelectedDocument([
                      renderContent("checkpoints_testing_2_2_1_file"),
                      renderContent("checkpoints_testing_2_2_2_file"),
                    ])
                  }
                >
                  <i className="far fa-file-search"></i>
                  <div>{renderContent("checkpoints_testing_2_2_link")}</div>
                </div>
              </div>

              <div className="content" style={{ marginTop: "32px" }}>
                <div className="property__document-a">
                  <a
                    href={renderContent("checkpoints_testing_2_3_1_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img
                      src={renderContent("checkpoints_testing_2_3_1_file")}
                    />
                  </a>
                  <a
                    href={renderContent("checkpoints_testing_2_3_2_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img
                      src={renderContent("checkpoints_testing_2_3_2_file")}
                    />
                  </a>
                </div>
                <div
                  className="link"
                  onClick={() =>
                    setSelectedDocument([
                      renderContent("checkpoints_testing_2_3_1_file"),
                      renderContent("checkpoints_testing_2_3_2_file"),
                    ])
                  }
                >
                  <i className="far fa-file-search"></i>
                  <div>{renderContent("checkpoints_testing_2_3_link")}</div>
                </div>
                <div
                  className="link"
                  onClick={() =>
                    setSelectedDocument([
                      renderContent("checkpoints_testing_2_4_file"),
                    ])
                  }
                >
                  <i className="far fa-file-search"></i>
                  <div>{renderContent("checkpoints_testing_2_4_link")}</div>
                </div>
              </div>
            </div>
            <img
              className="property-minor__image"
              width="100%"
              src={renderContent("checkpoints_testing_2_image")}
            />
          </div>
        </div>
      </div>
      <div className="property-minor layout">
        <div className="container layout--half">
          <img
            width="100%"
            src={renderContent("checkpoints_testing_3_left_image")}
          />

          <div className="content">
            <div className="property__header">
              <div className="property__number">3</div>
              <div className="title">
                {renderContent("checkpoints_testing_3_title")}
              </div>
            </div>
            <div className="divider" />
            <div>{renderContent("checkpoints_testing_3_description")}</div>
            <img
              width="100%"
              src={renderContent("checkpoints_testing_3_right_image")}
            />
            <div
              className="link"
              onClick={() =>
                setSelectedDocument([
                  renderContent("checkpoints_testing_3_file"),
                ])
              }
            >
              <i className="far fa-file-search"></i>
              <div>{renderContent("checkpoints_testing_3_link")}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="property-minor layout"
        style={{
          backgroundColor: "#ECEEE1",
        }}
      >
        <div className="container layout--half">
          <img
            className="property-minor__image--mobile"
            width="100%"
            src={renderContent("checkpoints_testing_4_image")}
          />
          <div className="content">
            <div className="property__header">
              <div className="property__number">4</div>
              <div className="title">
                {renderContent("checkpoints_testing_4_title")}
              </div>
            </div>
            <div className="divider" />
            <div>{renderContent("checkpoints_testing_3_description")}</div>
            <div className="content" style={{ marginTop: "32px" }}>
              <div className="property__document-d">
                <div>
                  <a
                    href={renderContent("checkpoints_testing_4_1_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img src={renderContent("checkpoints_testing_4_1_file")} />
                  </a>
                  <div
                    className="link"
                    onClick={() =>
                      setSelectedDocument([
                        renderContent("checkpoints_testing_4_1_file"),
                      ])
                    }
                  >
                    <i className="far fa-file-search"></i>
                    <div>{renderContent("checkpoints_testing_4_1_link")}</div>
                  </div>
                </div>
                <div>
                  <a
                    href={renderContent("checkpoints_testing_4_2_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img src={renderContent("checkpoints_testing_4_2_file")} />
                  </a>
                  <div
                    className="link"
                    onClick={() =>
                      setSelectedDocument([
                        renderContent("checkpoints_testing_4_2_file"),
                      ])
                    }
                  >
                    <i className="far fa-file-search"></i>
                    <div>{renderContent("checkpoints_testing_4_2_link")}</div>
                  </div>
                </div>
                <div>
                  <a
                    href={renderContent("checkpoints_testing_4_3_document")}
                    target="_blank"
                    className="property__document"
                  >
                    <img src={renderContent("checkpoints_testing_4_3_file")} />
                  </a>
                  <div
                    className="link"
                    onClick={() =>
                      setSelectedDocument([
                        renderContent("checkpoints_testing_4_3_file"),
                      ])
                    }
                  >
                    <i className="far fa-file-search"></i>
                    <div>{renderContent("checkpoints_testing_4_3_link")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="property-minor__image"
            width="100%"
            src={renderContent("checkpoints_testing_4_image")}
          />
        </div>
      </div>

      <div id="qualification" className="layout">
        <div ref={qualificationContainer} />
        <div className="container property__navbar">
          <img src={BesutoLogo} />
          <div className="property__divider" />
          <div className="title">
            <div>{t("pros extra 1")}</div>
            <div>{t("pros extra 2")}</div>
          </div>
        </div>
      </div>

      <div className="container property__menu">
        <div
          className={cn("property__menu-item", {
            "property__menu-item--active": otherPropertyIndex === 0,
          })}
          onClick={() => handleChangeSlide(0)}
        >
          <div className="content-text">
            {renderContent("qualification_title_1")}
          </div>
        </div>
        <div
          className={cn("property__menu-item", {
            "property__menu-item--active": otherPropertyIndex === 1,
          })}
          onClick={() => handleChangeSlide(1)}
        >
          <div className="content-text">
            {renderContent("qualification_title_2")}
          </div>
        </div>
        <div
          className={cn("property__menu-item", {
            "property__menu-item--active": otherPropertyIndex === 2,
          })}
          onClick={() => handleChangeSlide(2)}
        >
          <div className="content-text">
            {renderContent("qualification_title_3")}
          </div>
        </div>
        <div
          className={cn("property__menu-item", {
            "property__menu-item--active": otherPropertyIndex === 3,
          })}
          onClick={() => handleChangeSlide(3)}
        >
          <div className="content-text">
            {renderContent("qualification_title_4")}
          </div>
        </div>
        <div
          className={cn("property__menu-item", {
            "property__menu-item--active": otherPropertyIndex === 4,
          })}
          onClick={() => handleChangeSlide(4)}
        >
          <div className="content-text">
            {renderContent("qualification_title_5")}
          </div>
        </div>
      </div>

      <div className="property__menu--mobile">
        <Slider {...settingsPropertyMenu} ref={sliderMenu}>
          <div>
            <div
              className={cn("property__menu-item", {
                "property__menu-item--active": otherPropertyIndex === 0,
              })}
              onClick={() => handleChangeSlide(0)}
            >
              <div className="content-text">
                {renderContent("qualification_title_1")}
              </div>
            </div>
          </div>
          <div>
            <div
              className={cn("property__menu-item", {
                "property__menu-item--active": otherPropertyIndex === 1,
              })}
              onClick={() => handleChangeSlide(1)}
            >
              <div className="content-text">
                {renderContent("qualification_title_2")}
              </div>
            </div>
          </div>
          <div>
            <div
              className={cn("property__menu-item", {
                "property__menu-item--active": otherPropertyIndex === 2,
              })}
              onClick={() => handleChangeSlide(2)}
            >
              <div className="content-text">
                {renderContent("qualification_title_3")}
              </div>
            </div>
          </div>
          <div>
            <div
              className={cn("property__menu-item", {
                "property__menu-item--active": otherPropertyIndex === 3,
              })}
              onClick={() => handleChangeSlide(3)}
            >
              <div className="content-text">
                {renderContent("qualification_title_4")}
              </div>
            </div>
          </div>
          <div>
            <div
              className={cn("property__menu-item", {
                "property__menu-item--active": otherPropertyIndex === 4,
              })}
              onClick={() => handleChangeSlide(4)}
            >
              <div className="content-text">
                {renderContent("qualification_title_5")}
              </div>
            </div>
          </div>
        </Slider>
      </div>

      <div className="other-property layout">
        <div className="container">
          <Slider ref={slider} {...settings}>
            <div className="other-property__item">
              <img src={renderContent("qualification_image_1")} />

              <div className="other-property__card">
                <div className="other-property__content">
                  <div className="other-property__title content-text">
                    {renderContent("qualification_title_1")}
                  </div>
                  <div className="content-text">
                    {renderContent("qualification_description_1")}
                  </div>
                </div>
              </div>
            </div>
            <div className="other-property__item">
              <img src={renderContent("qualification_image_2")} />
              <div className="other-property__card">
                <div className="other-property__content">
                  <div className="other-property__title content-text">
                    {renderContent("qualification_title_2")}
                  </div>
                  <div className="content-text">
                    {renderContent("qualification_description_2")}
                  </div>
                </div>
              </div>
            </div>
            <div className="other-property__item">
              <img src={renderContent("qualification_image_3")} />
              <div className="other-property__card">
                <div className="other-property__content">
                  <div className="other-property__title content-text">
                    {renderContent("qualification_title_3")}
                  </div>
                  <div className="content-text">
                    {renderContent("qualification_description_3")}
                  </div>
                </div>
              </div>
            </div>
            <div className="other-property__item">
              <img src={renderContent("qualification_image_4")} />
              <div className="other-property__card">
                <div className="other-property__content">
                  <div className="other-property__title content-text">
                    {renderContent("qualification_title_4")}
                  </div>
                  <div className="content-text">
                    {renderContent("qualification_description_4")}
                  </div>
                </div>
              </div>
            </div>
            <div className="other-property__item">
              <img src={renderContent("qualification_image_5")} />
              <div className="other-property__card">
                <div className="other-property__content">
                  <div className="other-property__title content-text">
                    {renderContent("qualification_title_5")}
                  </div>
                  <div className="content-text">
                    {renderContent("qualification_description_5")}
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <div id="how_to" className="layout instruction">
        <div ref={howToUseContainer} />
        <div className="container">
          <div className="content">
            <div className="title title--primary">{t("instruction 1")}</div>
            <div className="instruction__list">
              <img src={renderContent("how_to_image_step_1")} />

              <img src={renderContent("how_to_image_step_2")} />

              <img src={renderContent("how_to_image_step_3")} />
            </div>
            <div className="instruction__list">
              <div className="instruction__line" />
              <div className="content">
                <div className="instruction__number">1</div>
                <div className="instruction__meta">
                  {renderContent("how_to_title_step_1")}
                </div>
                <div className="instruction__description">
                  {renderContent("how_to_title_description_1")}
                </div>
              </div>
              <div className="content">
                <div className="instruction__number">2</div>
                <div className="instruction__meta">
                  {renderContent("how_to_title_step_2")}
                </div>
                <div className="instruction__description">
                  {renderContent("how_to_title_description_2")}
                </div>
              </div>
              <div className="content">
                <div className="instruction__number">3</div>
                <div className="instruction__meta">
                  {renderContent("how_to_title_step_3")}
                </div>
                <div className="instruction__description">
                  {renderContent("how_to_title_description_3")}
                </div>
              </div>
            </div>
          </div>

          <div className="instruction__slider">
            <Slider {...settingsInstruction}>
              <div>
                <img src={renderContent("how_to_image_step_1")} />
                <div className="content instruction__content">
                  <div className="instruction__number">1</div>
                  <div className="instruction__meta">
                    {renderContent("how_to_title_step_1")}
                  </div>
                  <div className="instruction__description">
                    {renderContent("how_to_description_step_1")}
                  </div>
                </div>
              </div>
              <div>
                <img src={renderContent("how_to_image_step_2")} />
                <div className="content instruction__content">
                  <div className="instruction__number">2</div>
                  <div className="instruction__meta">
                    {renderContent("how_to_title_step_2")}
                  </div>
                  <div className="instruction__description">
                    {renderContent("how_to_description_step_2")}
                  </div>
                </div>
              </div>
              <div>
                <img src={renderContent("how_to_image_step_3")} />
                <div className="content instruction__content">
                  <div className="instruction__number">3</div>
                  <div className="instruction__meta">
                    {renderContent("how_to_title_step_3")}
                  </div>
                  <div className="instruction__description">
                    {renderContent("how_to_description_step_3")}
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div id="faqs" className="qa layout">
        <div ref={faqsContainer} />
        <div className="container layout--half">
          <img width="100%" src={renderContent("faq_image")} />
          <div className="content">
            <div className="title title--primary">{t("faq 1")}</div>
            <div className="qa__list">
              {faq.map((x, i) => (
                <div key={`faq-${i}`} className="qa__item">
                  <div className="qa__header">
                    <div className="qa__number">{i + 1}.</div>
                    <div className="qa__title">
                      {x[`question_${i18n.language}`]}
                    </div>
                    <div
                      className="qa__collapse"
                      onClick={() => handleChangeAnswer(i + 1)}
                    >
                      <i
                        className={cn("fal", {
                          "fa-minus": answerIndex === i + 1,
                          "fa-plus": answerIndex !== i + 1,
                        })}
                      />
                    </div>
                  </div>
                  {answerIndex === i + 1 && (
                    <div className="qa__content">
                      {x[`answer_${i18n.language}`]}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div id="awards" className="award layout">
        <div ref={awardsContainer} />
        <div className="container layout--half">
          <img
            width="100%"
            src={renderContent("award_image_left")}
            alt="award"
          />
          <div className="content">
            <div className="title--primary">
              <span className="quote" /> {t("award 1")}
            </div>
            <div className="meta">{renderContent("award_title")}</div>
            <div
              className="link"
              onClick={() => setSelectedDocument([renderContent("award_file")])}
            >
              <i className="far fa-file-search"></i>
              <div>{renderContent("award_link")}</div>
            </div>
            <img
              className="award__image"
              src={renderContent("award_image_right")}
              alt="award medal"
            />
          </div>
        </div>
      </div>

      <div id="stores" className="location layout">
        <div ref={storesContainer} />
        <div className="container content">
          <div className="title">{t("location 1")}</div>
          <div className="location__box">
            {selctedLocation && (
              <div className="location__detail">
                <div>
                  <div className="location__detail-title">
                    {selctedLocation[`name_${i18n.language}`]}
                  </div>
                  <div className="location__detail-description">
                    {selctedLocation[`address_${i18n.language}`]}{" "}
                  </div>
                </div>
                <a
                  className="location__navigation"
                  href={selctedLocation.google_map_link}
                  target="_blank"
                >
                  <i className="fad fa-map-marked fa-2x" />
                  <div>เส้นทาง</div>
                </a>
              </div>
            )}
            <div className="location__search">
              <div className="location__search-header">ค้นหาร้านค้า</div>
              <div className="location__search-content">
                <div className="location__search-nearby" onClick={getLocation}>
                  ค้นหาโดยร้านที่อยู่ใกล้
                </div>
                <div>เลือกภูมิภาค</div>
                <select>
                  <option>THAILAND</option>
                </select>
                <div className="location__input">
                  <input
                    placeholder="อำเภอ จังหวัดหรือรหัสไปรษณีย์"
                    value={keyword}
                    onChange={handleSearch}
                  />
                  <i className="fal fa-search" />
                </div>
              </div>
            </div>
            <div className="location__map">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyB7sAG04k9yNvANbNfXOKf1BaQqzMXEgmo",
                }}
                defaultCenter={{
                  lat: 13.7563,
                  lng: 100.5018,
                }}
                defaultZoom={zoom}
                center={center}
                onBoundsChange={handleBoundsChange}
              >
                {store.map((x) =>
                  x.latitude ? (
                    <Pin
                      lat={x.latitude}
                      lng={x.longitude}
                      onClick={() => selectLocation(x.no)}
                    />
                  ) : null
                )}
              </GoogleMapReact>
            </div>
          </div>
          <div className="location__list">
            <div className="location__item location__item--header">
              <div style={{ textAlign: "center" }}>{t("location 2")}</div>
              <div>{t("location 3")}</div>
              <div>{t("location 4")}</div>
              <div>{t("location 5")}</div>
            </div>
            {renderLocation().map((x) => (
              <div
                onClick={() => selectLocation(x.code)}
                className={cn("location__item location__item--content", {
                  "location__item--active":
                    selctedLocation && selctedLocation.code === x.code,
                })}
              >
                <div className="location__name">
                  <div className="location__icon">
                    <img src={x.logo} />
                  </div>
                  <div>{x[`name_${i18n.language}`]}</div>
                </div>
                <div>{x[`address_${i18n.language}`]} </div>
                <div>{x.phone_number}</div>
                <div>{x[`open_time_${i18n.language}`]}</div>
              </div>
            ))}
          </div>
          <Pagiantion
            page={Math.ceil(filteredStore.length / 10)}
            active={page}
            onChange={handleChangePage}
          />
        </div>
      </div>

      <div id="about_us" className="contact layout">
        <div ref={aboutUsContainer} />
        <div className="contact__box">
          <div className="container content">
            <div className="title title--primary">{t("contact 1")}</div>
            <div className="contact__content">
              <i className="fal fa-map-marker-alt" />
              <div>
                <div className="contact__title">{t("contact 2")}</div>
                <div>{renderContent("company_name")}</div>
                <div>{renderContent("company_address")}</div>
              </div>
            </div>
            <div className="contact__content">
              <i className="fal fa-phone-alt" />
              <div>
                <div className="contact__title">{t("contact 5")}</div>
                <a href={`tel:${t("contact 6")}`}>
                  {renderContent("phone_number")}
                </a>
              </div>
            </div>
            <div className="contact__content">
              <i className="fal fa-envelope" />
              <div>
                <div className="contact__title">{t("contact 7")}</div>
                <a href={`mailto:${renderContent("email")}`}>
                  {renderContent("email")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <img
          className="contact__image"
          src={renderContent("contact_background_desktop")}
        />
        <img
          className="contact__image--mobile"
          src={renderContent("contact_background_mobile")}
        />
      </div>
      <Footer content={content} />
    </div>
  );
}

export default App;
