import React from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import BesutoLogo from "../resources/images/logo-2.png";

const Footer = ({ content }) => {
  const { t, i18n } = useTranslation();

  function renderContent(key) {
    if (!content) return null;
    let x = content[`${key}_${i18n.language}`];
    if (!x) {
      x = content[`${key}`];
    }
    return x;
  }

  return (
    <>
      <ReactTooltip place="top" multiline="true" event="click" />
      <div className="footer">
        <a>
          <img className="footer__logo" src={BesutoLogo} alt="footer logo" />
        </a>
        <div className="footer__copy-right">
          © Copyright 2020. All rights reserved
        </div>
        <div className="footer__link">
          <ul>
            <li
              data-tip={`*Department of Microbiology, Faculty of Medicine, Chulalongkorn University<br />
**Faculty of Tropical Medicine, Mahidol University<br />
***Veterinary Diagnostic Laboratory, Faculty of Veterinary Science, Chulalongkorn University`}
            >
              Disclaimer
            </li>
          </ul>
          <div className="footer__social">
            <a href={renderContent("facebook")} target="_blank">
              <i className="fab fa-facebook" />
            </a>
            <a href={renderContent("line")} target="_blank">
              <i className="fab fa-line" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer--mobile">
        <a>
          <img className="footer__logo" src={BesutoLogo} alt="footer logo" />
        </a>

        <div className="footer__social">
          <a href={renderContent("facebook")} target="_blank">
            <i className="fab fa-facebook" />
          </a>
          <a href={renderContent("line")} target="_blank">
            <i className="fab fa-line" />
          </a>
          <div className="navbar__button navbar__button--borderless">
            <i className="fas fa-globe-americas navbar__button--active" />
            {i18n.language === "en" ? (
              <span
                className="navbar__button--active"
                onClick={() => i18n.changeLanguage("en")}
              >
                EN
              </span>
            ) : (
              <span
                className="navbar__button--active"
                onClick={() => i18n.changeLanguage("th")}
              >
                TH
              </span>
            )}
            <span>|</span>
            {i18n.language === "en" ? (
              <span onClick={() => i18n.changeLanguage("th")}>TH</span>
            ) : (
              <span onClick={() => i18n.changeLanguage("en")}>EN</span>
            )}
          </div>
        </div>

        <div className="footer__copy-right">
          © Copyright 2020. All rights reserved
        </div>
        <div className="footer__link">
          <ul>
            <li
              data-tip={`*Department of Microbiology, Faculty of Medicine, Chulalongkorn University<br />
**Faculty of Tropical Medicine, Mahidol University<br />
***Veterinary Diagnostic Laboratory, Faculty of Veterinary Science, Chulalongkorn University`}
            >
              Disclaimer
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
