import i18n from 'i18next';
import {
    initReactI18next
} from 'react-i18next';

const resources = {
    en: {
        translation: {
            "new": "New!",
            "bannner 1": "Protect your hand with assurance.",
            "bannner 2": "Feel Safe & Secure at Every Touch.",
            "bannner 3": "Dermatologically-tested",
            "bannner 4": "by Thai leading medical institutes.",
            "bannner 5": "Buy",

            "about established 1": "Background Information",
            "about established 2": "Besuto12 is a disinfectant product resulted from a collaboration between affiliated organizations under Thailand Science Park",
            "about established 3": "Since January 2020, due to Coronavirus pandemic, there has been Personal and hand hygiene have exceeded more than 100,000 around the world. The top priorities that various healthcare institutions and World Health Organization have been promoting campaigns to create more awareness towards this fatal outbreak.",
            "about 1": `Since we acknowledged the significance of preventing these dangerous threats caused by Coronavirus, Besuto12 decided to conglomerate with affiliated organizations under Thailand Science Park.`,
            "about 2": `and developed an alcohol-free sanitizer product which is suitable for all skins
            called, “Besuto12”.`,

            "pros 1": "Besuto Check Point",
            "pros 2": `Applying Besuto12 on your hands just only for 15 seconds,
            it could prevent all kinds of Germ up to 99.99%`,
            "pros 3": `Besuto12 has been clinically proven to disinfect virus bacteria and fungi-tested by various Thai leading medical institutions.`,
            "pros 4": `With Thin Film Technology, Besuto12 can protect you more than 12 hours.`,
            "pros 5": `With Green Tea, Grape seed and Cucumber Extract, these components will facilitate you in moisturizing and softening your skin.`,

            "property 1 1": "Germ Prevention",
            "property 1 2": "“Besuto12” contains active ingredient, “BKC-Natural Complex” that prevents bacterial agents, viruses, Fungi, any antimicrobial resistance and Coronavirus up to 99.99% in less than 60 seconds.",
            "property 1 3": "Click to see Bacteria,Virus,Mycovirus Prevention Performance",

            "property 2 1": "Clinically proven to disinfect virus bacteria and fungi.-tested Product",
            "property 2 2": "Besuto12 is dermatologically-tested by several Thai leading medical institutions which guaranteed Bacteria/Virus/Mycovirus Prevention Rate up to 99.99%",
            "property 2 3": "Threats that Besuto12 could prevent are listed as follows:",
            "property 2 4": "1. Virus Influenza Virus-H1N1*** H3N2*, Coronavirus PEDV**",
            "property 2 5": "2. Pseudomonas aeruginosa*, Staphylococcus aureus-MRSA*",
            "property 2 6": "3. Fungus Aspergillus Flavus*",
            "property 2 7": "Dermatologically-tested from various lab",
            "property 2 8": "* Department of Microbiology, Faculty of Medicine, Chulalongkorn University",
            "property 2 9": "** Faculty of Tropical Medicine, Mahidol University",
            "property 2 10": "*** Veterinary Diagnostic Laboratory, Faculty of Veterinary Science, Chulalongkorn University",
            "property 2 11": "Click to see Virus Performance",
            "property 2 12": "Click to see Bacteria Performance",
            "property 2 13": "Click to see Mycovirus Performance",
            "property 2 14": "Click to see General Germ Performance",

            "property 3 1": "Long-lasting duration that could protect you up to 12 hours.",
            "property 3 2": `Thin Film Technology compounded with active Ingredients will coat any applied area and release its decontamination components that could terminate core germ agents up to 12 hours without having to re-apply it again. Despite the heat received from other substances, its efficiency will always remain; therefore, Besuto12 users can always feel safe at all times although they might have any direct or indirect contact with the germ.`,
            "property 3 3": "Click to see Besuto12 Performance and Efficiency",

            "property 4 1": "Natural Extract Components",
            "property 4 2": `Besuto12 retains the moisture on your skin while keeping it soft.
            It can also alleviate the causes of white flakes occurred from skin dryness
            that may eventually lead to further bacterial contamination that is capable of harming your body easily and quick.
            `,
            "property 4 3": "Click to see Mycovirus Performance",
            "property 4 4": "Click to see Nontoxic to Human Fibroblast cell Performance",
            "property 4 5": "Click to see Non-irritation Performance",

            "pros extra 1": "Besuto12 is unlike other,",
            "pros extra 2": "typical hand sanitizers.",

            "pros extra 3 1": `Besuto12 does not only serve as a disinfectant,`,
            "pros extra 3 2": `but it could remarkably prevent all kinds of Germ for a very long time.`,
            "pros extra 3 3": `Alcohol can only disinfect Germ during the time of usage. As for Besuto12, not only sanitizing Germ but it could also protect you away from all kinds of Germ  for a very long period of time.`,
            "pros extra 4 1": `Since Besuto12 nourishes your skin,`,
            "pros extra 4 2": `skin dryness will never occur.`,
            "pros extra 4 3": `Which in the long use helps to protect your hands from dry skin. To compare with Alcohol that has side effects, the skin peels apart. Will be a carrier for germs to enter the body easily and quickly.`,
            "pros extra 5 1": `One unit of Besuto12 can be used as small soybean seed (about 0.5 ml.)`,
            "pros extra 5 2": `Can be used 120 times.`,
            "pros extra 5 3": `One unit of Besuto12 can be used as small soybean seed (about 0.5 ml.). Can be used 120 times. Because the gel does not evaporate like alcohol and lasts for a long time, it does not need to be used often.`,
            "pros extra 6 1": `Besuto12 is much safer than alcohol`,
            "pros extra 6 2": `because it doesn’t evaporate.`,
            "pros extra 6 3": `The concentration does not decrease like Alcohol. (Which may affect the efficacy of Alcohol) and is not harmful when inhaled.`,
            "pros extra 7 1": `Besuto12 is much safer than alcohol`,
            "pros extra 7 2": `since it is non-inflammable.`,
            "pros extra 7 3": `It does not contain Alcohol and is a non-flammable natural extract, so it is safe when approaching heat.`,

            "instruction 1": "How To Use in 3 Steps",
            "instruction 2 1": "Gently squeeze Besuto12 out of the container with In small quantity as small as a soybean.",
            "instruction 2 2": "(Considerable amount of BESUTO won’t create excessive stickiness.)",
            "instruction 3 1": "Apply on your hands for 15 seconds to create thin film layer",
            "instruction 3 2": "(both on the back and palms and also between your fingers and nails).",
            "instruction 4 1": "It protects up to 12 hours.",

            "award 1": "Award",
            "award 2": "Besuto12 received a Silver medal and Canadian Special Awards from iCan2020, an international invention and innovation competition in Canada.",
            "award 3": "Click to see Besuto12 from ICAN2020 Award",

            "location 1": "Distribution Centre",
            "location 2": "Store",
            "location 3": "Location",
            "location 4": "Contact phone number",
            "location 5": "Opening Time",

            "faq 1": "FAQs",

            "contact 1": `Contact US`,
            "contact 2": `Company Address`,
            "contact 3": `HEALTHINESS CO., LTD. (Headquater)`,
            "contact 4": `37 Soi Sirikasem, 7 Kwaeng Bang Phai, Khet Bang Kae, Bangkok, Thailand 10160`,
            "contact 5": `Telephone`,
            "contact 6": `02-157-2704`,
            "contact 7": `E-mail`,
            "contact 8": `Info@vhealthiness.com`,
        },
    },
    th: {
        translation: {
            "new": "ใหม่",
            "bannner 1": "เคลือบความมั่นใจ",
            "bannner 2": "ปลอดภัยทุกสัมผัส",
            "bannner 3": "ผ่านการทดสอบจาก",
            "bannner 4": "สถาบันการแพทย์ชั้นนำของไทย",
            "bannner 5": "คลิกเพื่อซื้อ",

            "about established 1": "ที่มาของ Besuto Twelve เบซูโตะ ทเว็ลฟ ",
            "about established 2": "แบรนด์ “เบซูโตะ” ชื่อแบรนด์มีความหมายว่า “Best” “ดีที่สุด” ในภาษาญี่ปุ่น แบรนด์ “เบซูโตะ” ก่อตั้งปี 2009 โดยเริ่มต้นจากผลิตภัณฑ์แอลกอฮอล์เจลล้างมือและผลิตภัณฑ์สลายกลิ่น โดยมุ่งเน้นสร้างผลิตภัณฑ์ที่มีนวัตกรรมจากสารสกัดธรรมชาติที่ปลอดภัย",
            "about established 3": `ในปี 2020 ที่เกิด Coronavirus pandemic ขึ้นตั้งแต่ช่วงต้นเดือนมกราคม
            2020 และมีผู้ป่วยเพิ่มจำนวนขึ้นอย่างรวดเร็วในทั่วโลก
            รวมทั้งมีผู้เสียชีวิตมากหลายแสนราย การรักษาสุขอนามัย
            รวมถึงการดูแลความสะอาดมืออยู่เสมอกลายเป็นสิ่งสำคัญอันดับต้นที่สาธารณสุขทั่วโลก รวมถึงองค์กร WHO รณรงค์ให้ประชาชนทุกคนในโลกต้องปฏิบัติอย่างเคร่งครัด`,

            "about 1": `Besuto เห็นความสำคัญของผลิตภัณฑ์เพื่อป้องกันเชื้อโรคจึงได้ตกลงความร่วมมือกับ บริษัทในกลุ่มอุทยานวิทยาศาสตร์ประเทศไทย (Thailand Science Park)`,
            "about 2": `คิดค้นผลิตภัณฑ์นวัตกรรมใหม่ เจลฆ่าเชื้อ ปราศจากแอลกอฮอล์ สำหรับใช้บนผิวหนังคน ภายใต้ชื่อ Besuto Twelve “เบซูโตะ ทเว็ลฟ” ขึ้นมา`,

            "pros 1": "คุณสมบัติเด่น",
            "pros 2": `ลูบให้ทั่วมือ 15 วินาที ยับยั้งเชื้อโรค 99.99%`,
            "pros 3": `ผ่านการทดสอบจากสถาบัน การแพทย์ชั้นนำของไทย`,
            "pros 4": `Thin Film Technology ช่วยปกป้องนานสูงสุด 12 ชั่วโมง**`,
            "pros 5": `Green Tea, Grape seed และ Cucumber Extract ล็อคผิวนุ่ม เติมความชุ่มชื้น`,

            "property 1 1": "ยับยั้งเชื้อโรค",
            "property 1 2": "“เบซูโตะ ทเว็ลฟ”มีสารออกฤทธิ์เป็น BKC-Natural Complex สารยั้บยั้งเชื้อโรคได้ทั้งแบคทีเรีย ไวรัสเชื้อรา รวมถึงเชื้อดื้อยาที่เป็นปัญหาในโรงพยาบาลและ Coronavirus ได้ถึง 99.99% ภายในเวลาน้อยกว่า 60 วินาที",
            "property 1 3": "ผลการทดสอบการยับยั้งเชื้อไวรัส แบคทีเรีย เชื้อรา",

            "property 2 1": "ผ่านการทดสอบจากสถาบันการแพทย์ชั้นนำ",
            "property 2 2": "ผลิตภัณฑ์ผ่านการทดสอบจากสถาบันการแพทย์ชั้นนำของไทยว่าสามารถยับยั้งเชื้อได้ถึง 99.99%",
            "property 2 3": "",
            "property 2 4": "1. เชื้อไวรัส Influenza Virus-H1N1*** H3N2*, Coronavirus-Porcine Epidemic Diarrhea Virus (PEDV)**",
            "property 2 5": "3. เชื้อแบคทีเรีย Pseudomonas aeruginosa* Staphylococcus aureus -MRSA*",
            "property 2 6": "4. เชื้อรา Aspergillus Flavus*",
            "property 2 7": "ได้รับการทดสอบในห้องปฏิบัติจาก",
            "property 2 8": "* หน่วยไวรัสวิทยา คณะแพทย์ศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
            "property 2 9": "** เวชศาสตร์เขตร้อน มหาวิทยาลัยมหิดล",
            "property 2 10": "*** คณะสัตว์แพทย์ จุฬาลงกรณ์มหาวิทยาลัย",
            "property 2 11": "ดูผลการทดสอบการยับยั้งเชื้อไวรัส",
            "property 2 12": "ดูผลการทดสอบการยับยั้งเชื้อแบคทีเรีย",
            "property 2 13": "ดูผลการทดสอบการยับยั้งเชื้อรา",
            "property 2 14": "ดูผลการทดสอบ General Germ Test",

            "property 3 1": "ปกป้องยาวนาน 12 ชั่วโมง",
            "property 3 2": `Thin Film Technology ชั้นฟิล์มบางเบาเคลือบผิวที่ผสาน Active Ingredients ค่อยๆปลดปล่อยสารฆ่าเชื้อที่ออกฤทธิ์ตรงเข้าทำลายเชื้อโรคถึงแก่นเซลล์ยาวนานถึง 12 ช.ม. โดยไม่ต้องทาเนื้อเจลซ้ำอีกและผู้ใช้ไม่ล้างเนื้อฟิล์มออก ประสิทธิภาพไม่ลดลงเมื่อเจอความร้อนต่างจากสารชนิดอื่น ทำให้ผู้ใช้ปลอดภัยแม้สัมผัสกับเชื้อโรคอีกในภายหลัง`,
            "property 3 3": "ดูผลการทดสอบประสิทธิภาพของ Besuto Twelve",

            "property 4 1": "ผสานคุณค่าธรรมชาติ",
            "property 4 2": `ด้วยตัวเนื้อฟิล์มบางเบาจะช่วยล็อคผิวนุ่ม และเติมความชุ่มชื้นด้วยสารสกัดจากชาเขียว, เมล็ดองุ่น และแตงกวา ช่วยถนอมมือ ไม่ทำให้ผิวแห้งแตก ลอก ซึ่งอาจเป็นช่องทางให้เชื้อโรคและสิ่งสกปรกเข้าสู่ร่างกายได้ง่ายและรวดเร็ว`,
            "property 4 3": "ดูผลการทดสอบการยับยั้งเชื้อรา",
            "property 4 4": "ดูผลการทดสอบ Nontoxic to Human Fibroblast cell",
            "property 4 5": "ดูผลการทดสอบ Non-irritation",

            "pros extra 1": "มีคุณสมบัติอื่นๆ",
            "pros extra 2": "ที่เหนือกว่าแอลกอฮอล์เจล",

            "pros extra 3 1": `Besuto12 ไม่เพียงแต่ยับยั้งเชื้อโรค`,
            "pros extra 3 2": `แต่ยังออกฤทธิ์ปกป้องได้ยาวนาน`,
            "pros extra 3 3": `แอลกอฮอล์ออกฤทธิ์ยับยั้งเชื้อโรคได้แค่ช่วงเวลาที่ใช้ แต่
            Besuto12 ไม่เพียงแต่ยับยั้งเชื้อโรค
            แต่ยังออกฤทธิ์ปกป้องได้ยาวนาน`,
            "pros extra 4 1": `Besuto12 ถนอมมือ ไม่ทำให้`,
            "pros extra 4 2": `ผิวหนังแห้ง ลอก`,
            "pros extra 4 3": `ซึ่งในระยะยาวช่วยถนอมมือไม่ให้มือแตกง่าย
            เมื่อเทียบกับแอลกอฮอล์ที่มีผลข้างเคียงทำให้หนังลอกแตก
            จะเป็นช่องทางให้เชื้อโรคและสิ่งสกปรกเข้าสู่ร่างกายได้ง่ายและอย่างรวดเร็ว`,
            "pros extra 5 1": `Besuto12 1 หลอดใช้ได้นาน`,
            "pros extra 5 2": `\u00A0`,
            "pros extra 5 3": `1 หลอดใช้ได้นาน เนื่องจากการใช้ 1 ครั้ง
            ใช้เจลปริมาณเพียงเม็ดถั่วเหลือง ( ประมาณ0.5ml )
            สามารถใช้ได้ถึง 120 ครั้ง ก็สามารถถูได้ทั่วมือ
            เนื่องจากเจลไม่ระเหยเหมือนแอลกอฮอล์และออกฤทธิ์ได้นานจึงไม่ต้องใช้บ่อย`,
            "pros extra 6 1": `Besuto12 ปลอดภัยกว่า`,
            "pros extra 6 2": `แอลกอฮอล์เนื่องจากไม่ระเหย`,
            "pros extra 6 3": `ความเข้มข้นไม่ลดลงเหมือนแอลกอฮอล์
            (ซึ่งอาจมีผลต่อประสิทธิภาพของแอลกอฮอล์)
            และไม่เป็นอันตรายเวลาสูดดม`,
            "pros extra 7 1": `Besuto12 ปลอดภัยกว่า`,
            "pros extra 7 2": `แอลกอฮอล์เนื่องจากไม่ติดไฟ`,
            "pros extra 7 3": `ไม่มีส่วนผสมของแอลกอฮอล์และเป็นสารสกัดจากธรรมชาติที่ไม่ติดไฟจึงปลอดภัยกว่าเมื่ออยู่ใกล้เปลวไฟ
            หรือความร้อน`,

            "instruction 1": "วิธีใช้ ง่ายๆ 3 ขั้นตอน",
            "instruction 2 1": "”บีบ” เจลเท่าเม็ดถั่วเหลือง",
            "instruction 2 2": "(จะเป็นปริมาณที่พอดีไม่ทำให้เหนียวเกินไป)",
            "instruction 3 1": "”ถู” ให้ทั่ว 15 วินาทีเพื่อสร้างฟิล์ม",
            "instruction 3 2": "(ทั้งฝ่ามือ หลังมือ ซอกนิ้วและซอกเล็บ)",
            "instruction 4 1": "”ปกป้อง” ยาวนานกว่า12ชั่วโมง",

            "award 1": "รางวัล",
            "award 2": "besuto12 ได้รับรางวัล Silver Medal และ Canadian Special Awards จาก 2020 International Invention Innovation Competition in Canada (ICAN2020) การประกวดผลงานสิ่งประดิษฐ์และนวัตกรรมระดับนานาชาติ ประเทศแคนนาดา",
            "award 3": "ดูรางวัล Besuto12 จาก ICAN2020",

            "location 1": "สถานที่จำหน่าย",
            "location 2": "สาขา",
            "location 3": "ที่อยู่",
            "location 4": "เบอร์โทรศัพท์",
            "location 5": "เวลาทำการ",

            "faq 1": "คำถามที่พบบ่อย (FAQs)",

            "contact 1": `ติดต่อเรา`,
            "contact 2": `ที่อยู่บริษัท`,
            "contact 3": `บริษัท เฮลทิเนส จำกัด (สำนักงานใหญ่)`,
            "contact 4": `เลขที่ 37 ซอย ศิริเกษม 7 แขวง บางไผ่ เขต บางแค กรุงเทพ 10160`,
            "contact 5": `เบอร์โทรศัพท์`,
            "contact 6": `02-157-2704`,
            "contact 7": `อีเมล`,
            "contact 8": `Info@vhealthiness.com`,
        },
    },
};

export default () => {
    i18n.use(initReactI18next).init({
        resources,
        lng: 'th',
        fallbackLng: 'th',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });
};