import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/home";
import ProductInformation from "./pages/admin/ProductInformation";
import Checkpoints from "./pages/admin/Checkpoints"
import Qualification from "./pages/admin/Qualification"
import HowTo from "./pages/admin/HowTo"
import Award from "./pages/admin/Award"
import Store from "./pages/admin/Store"
import Contact from "./pages/admin/Contact";
import Faq from "./pages/admin/Faq";
import Login from "./pages/admin/login";

const App = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/admin">
        <Redirect to="/admin/product-information" />
      </Route>
      <Route exact path="/admin/product-information">
        <ProductInformation />
      </Route>
      <Route exact path="/admin/checkpoints">
        <Checkpoints />
      </Route>
      <Route exact path="/admin/qualification">
        <Qualification />
      </Route>
      <Route exact path="/admin/awards">
        <Award />
      </Route>
      <Route exact path="/admin/how-to">
        <HowTo />
      </Route>
      <Route exact path="/admin/faqs">
        <Faq />
      </Route>
      <Route exact path="/admin/stores">
        <Store />
      </Route>
      <Route exact path="/admin/contact">
        <Contact />
      </Route>
      <Route exact path="/admin/login">
        <Login />
      </Route>
    </Switch>
  );
};

export default App;
