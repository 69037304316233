import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";
import BesutoLogo from "../resources/images/logo.png";

const menu = [
  {
    id: "product_information",
    name_th: "ที่มาของ Besuto12",
    name_en: "Product Information",
  },
  {
    id: "checkpoints",
    name_th: "คุณสมบัติเด่น",
    name_en: "Checkpoints",
  },
  {
    id: "qualification",
    name_th: "คุณสมบัติอื่นๆ",
    name_en: "Qualification",
  },
  {
    id: "how_to",
    name_th: "วิธีใช้",
    name_en: "How to Use",
  },
  {
    id: "faqs",
    name_th: "คำถามที่พบบ่อย",
    name_en: "FAQs",
  },
  {
    id: "awards",
    name_th: "รางวัล",
    name_en: "Awards",
  },
  {
    id: "stores",
    name_th: "สถานที่จำหน่าย",
    name_en: "Stores",
  },
  {
    id: "about_us",
    name_th: "ติดต่อเรา",
    name_en: "About us",
  },
];

const Navbar = ({ activeMenu, setActiveMenu, content }) => {
  const { t, i18n } = useTranslation();
  const [expandedMenu, setExpandedMenu] = useState(false);

  function renderContent(key) {
    if (!content) return null;
    let x = content[`${key}_${i18n.language}`];
    if (!x) {
      x = content[`${key}`];
    }
    return x;
  }

  return (
    <nav className="navbar">
      <a>
        <img className="navbar__logo" src={BesutoLogo} alt="logo" />
      </a>
      <div
        className="navbar__toggle"
        onClick={() =>
          setExpandedMenu((prevState) => (prevState ? false : true))
        }
      >
        <i
          className={cn("fas fa-2x", {
            "fa-times": expandedMenu,
            "fa-bars": !expandedMenu,
          })}
        />
      </div>
      <div className="navbar__menu">
        {menu.map((x) => (
          <AnchorLink
            offset="90"
            key={x.id}
            href={`#${x.id}`}
            onClick={() => setActiveMenu(x.id)}
            className={cn("navbar__item", {
              "navbar__item--active": activeMenu === x.id,
            })}
          >
            {x[`name_${i18n.language}`]}
          </AnchorLink>
        ))}
        <div className="navbar__item">
          <div className="navbar__link">
            <a href={renderContent("facebook")} target="_blank">
              <i className="fab fa-facebook" />
            </a>
            <a href={renderContent("line")} target="_blank">
              <i className="fab fa-line" />
            </a>
          </div>
        </div>

        <div className="navbar__item">
          <div className="navbar__button">
            <i className="fas fa-globe-americas navbar__button--active" />
            {i18n.language === "en" ? (
              <span
                className="navbar__button--active"
                onClick={() => i18n.changeLanguage("en")}
              >
                EN
              </span>
            ) : (
              <span
                className="navbar__button--active"
                onClick={() => i18n.changeLanguage("th")}
              >
                TH
              </span>
            )}
            <span>|</span>
            {i18n.language === "en" ? (
              <span onClick={() => i18n.changeLanguage("th")}>TH</span>
            ) : (
              <span onClick={() => i18n.changeLanguage("en")}>EN</span>
            )}
          </div>
        </div>
      </div>
      {expandedMenu && (
        <div className="navbar__collapse">
          {menu.map((x) => (
            <AnchorLink
              key={x.id}
              href={`#${x.id}`}
              onClick={() => {
                setActiveMenu(x.id);
                setExpandedMenu(false);
              }}
              className={cn("navbar__collapse-item", {
                "navbar__collapse-item--active": activeMenu === x.id,
              })}
            >
              {x[`name_${i18n.language}`]}
            </AnchorLink>
          ))}
          <div className="navbar__collapse-link">
            <div className="navbar__button">
              <i className="fas fa-globe-americas navbar__button--active" />
              {i18n.language === "en" ? (
                <span
                  className="navbar__button--active"
                  onClick={() => i18n.changeLanguage("en")}
                >
                  EN
                </span>
              ) : (
                <span
                  className="navbar__button--active"
                  onClick={() => i18n.changeLanguage("th")}
                >
                  TH
                </span>
              )}
              <span>|</span>
              {i18n.language === "en" ? (
                <span onClick={() => i18n.changeLanguage("th")}>TH</span>
              ) : (
                <span onClick={() => i18n.changeLanguage("en")}>EN</span>
              )}
            </div>

            <div className="navbar__link">
              <a href={renderContent("facebook")} target="_blank">
                <i className="fab fa-facebook" />
              </a>
              <a href={renderContent("line")} target="_blank">
                <i className="fab fa-line" />
              </a>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
