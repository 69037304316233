import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Divider,
  Table,
  Tag,
  Space,
  Popconfirm,
  Modal,
  message,
  Upload,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import * as api from "../../api";
import Layout from "../../components/admin/layout";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const Stores = () => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShownForm, setIsShownForm] = useState(false);
  const [store, setStore] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [image, setImage] = useState(undefined);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await api.getStore();
      setStore(data.store);
    } catch (error) {
      console.log("Error while call init()", error);
    }
    setIsLoading(false);
  }

  async function create(data) {
    try {
      setIsSubmitting(true);
      const res = await api.createStore(data);
      init();
      closeForm();
      message.success('บันทึกเรียบร้อยแล้ว');
    } catch (error) {
      message.error('เกิดข้อผิดพลา ดกรุณาลองใหม่อีกครั้ง');
      console.log("Error while call create()", error);
    }
    setIsSubmitting(false);
  }

  async function update(data) {
    try {
      setIsSubmitting(true);
      const res = await api.updateStore(selectedStore.id, data);
      init();
      closeForm();
      message.success('บันทึกเรียบร้อยแล้ว');
    } catch (error) {
      message.error('เกิดข้อผิดพลา ดกรุณาลองใหม่อีกครั้ง');
      console.log("Error while call update()", error);
    }
    setIsSubmitting(false);
  }

  async function destroy(id) {
    try {
      setIsSubmitting(true);
      const res = await api.destroyStore(id);
      init();
    } catch (error) {
      console.log("Error while call destroy()", error);
    }
    setIsSubmitting(false);
  }

  const onFinish = (values) => {
    const data = {...values, logo: image}
    if (selectedStore) {
      update(data);
    } else {
      create(data);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function selectStore(data) {
    setImage(data.logo)
    setSelectedStore(data);
    form.setFieldsValue(data);
  }

  function closeForm() {
    setIsShownForm(false);
    setImage(null)
    setSelectedStore(null);
    form.resetFields();
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  }

  function handleChange(info, key) {
    if (info.file.status === "done") {
      setImage(info.file.response.data.url);
    }
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const columns = [
    {
      title: "รูป",
      dataIndex: "logo",
      key: "logo",
      render: (text) => <img src={text} width="48" />,
    },
    {
      title: "รหัส",
      dataIndex: "code",
      key: "code",
      width: 80,
    },
    {
      title: "สาขาที่",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "ชื่อ (ไทย)",
      dataIndex: "name_th",
      key: "name_th",
      width: 240,
    },
    {
      title: "ชื่อ (อังกฤษ)",
      dataIndex: "name_en",
      key: "name_en",
      width: 240,
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "phone_number",
      key: "age",
      width: 160,
    },
    {
      title: "ที่อยู่ (ไทย)",
      dataIndex: "address_th",
      key: "address_th",
      width: 240,
    },
    {
      title: "ที่อยู่ (อังกฤษ)",
      dataIndex: "address_en",
      key: "address_en",
      width: 240,
    },
    {
      title: "เวลาเปิด-ปิด (ไทย)",
      dataIndex: "open_time_th",
      key: "open_time_th",
      width: 160,
    },
    {
      title: "เวลาเปิด-ปิด (อังกฤษ)",
      dataIndex: "open_time_en",
      key: "open_time_en",
      width: 160,
    },
    {
      title: "ละติจูด",
      dataIndex: "latitude",
      key: "latitude",
      width: 160,
    },
    {
      title: "ลองจิจูด",
      dataIndex: "longitude",
      key: "longitude",
      width: 160,
    },
    {
      title: "Google map link",
      dataIndex: "google_map_link",
      key: "google_map_link",
      width: 240,
    },
    {
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => selectStore(record)}>แก้ไข</a>
          <Popconfirm
            okText="ใช่"
            cancelText="ไม่ใช่"
            title="คุณต้องการที่จะลบใช่หรือไม่?"
            onConfirm={() => destroy(record.id)}
          >
            <a>ลบ</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Layout>
      <div style={{ textAlign: "right", marginBottom: "8px" }}>
        <Button icon={<PlusOutlined />} onClick={() => setIsShownForm(true)}>
          เพิ่ม
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={store}
        scroll={{ x: "max-content" }}
        loading={isLoading}
      />
      <Modal
        footer={null}
        visible={isShownForm || selectedStore}
        onCancel={closeForm}
      >
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="รหัส" name="code">
            <Input />
          </Form.Item>
          <Form.Item label="เลขที่" name="number">
            <Input />
          </Form.Item>
          <Form.Item label="ชื่อ (ไทย)" name="name_th">
            <Input />
          </Form.Item>
          <Form.Item label="ชื่อ (อังกฤษ)" name="name_en">
            <Input />
          </Form.Item>
          <Form.Item label="เบอร์โทรศัพท์" name="phone_number">
            <Input />
          </Form.Item>
          <Form.Item label="ที่อยู่ (ไทย)" name="address_th">
            <Input />
          </Form.Item>
          <Form.Item label="ที่อยู่ (อังกฤษ)" name="address_en">
            <Input />
          </Form.Item>
          <Form.Item label="เวลาเปิด-ปิด (ไทย)" name="open_time_th">
            <Input />
          </Form.Item>
          <Form.Item label="เวลาเปิด-ปิด (อังกฤษ)" name="open_time_en">
            <Input />
          </Form.Item>
          <Form.Item label="ละติจูด" name="latitude">
            <Input />
          </Form.Item>
          <Form.Item label="ลองจิจูด" name="longitude">
            <Input />
          </Form.Item>
          <Form.Item label="Google map link" name="google_map_link">
            <Input />
          </Form.Item>
          <Form.Item label="รูป" name="image">
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={api.upload}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {image ? (
                <img src={image} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button
              htmlType="button"
              onClick={closeForm}
              disabled={isSubmitting}
            >
              ยกเลิก
            </Button>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              บันทึก
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default Stores;
