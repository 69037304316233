import React, { useState, useEffect } from "react";
import { Form, Input, Button, Divider, message, Upload } from "antd";
import { PlusOutlined, FileOutlined } from "@ant-design/icons";
import * as api from "../../api";
import Layout from "../../components/admin/layout";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 8 },
};

let initImage = {
  checkpoint_background: undefined,
  checkpoint_image_1: undefined,
  checkpoint_image_2: undefined,
  checkpoint_image_3: undefined,
  checkpoint_image_4: undefined,
  checkpoints_testing_1_image: undefined,
  checkpoints_testing_1_file: undefined,
  checkpoints_testing_2_image: undefined,

  checkpoints_testing_2_1_1_document: undefined,
  checkpoints_testing_2_1_1_file: undefined,
  checkpoints_testing_2_1_2_document: undefined,
  checkpoints_testing_2_1_2_file: undefined,
  checkpoints_testing_2_1_3_document: undefined,
  checkpoints_testing_2_1_3_file: undefined,
  checkpoints_testing_2_1_4_document: undefined,
  checkpoints_testing_2_1_4_file: undefined,
  checkpoints_testing_2_1_5_document: undefined,
  checkpoints_testing_2_1_5_file: undefined,
  checkpoints_testing_2_1_6_document: undefined,
  checkpoints_testing_2_1_6_file: undefined,
  checkpoints_testing_2_1_7_document: undefined,
  checkpoints_testing_2_1_7_file: undefined,
  checkpoints_testing_2_2_1_document: undefined,
  checkpoints_testing_2_2_1_file: undefined,
  checkpoints_testing_2_2_2_document: undefined,
  checkpoints_testing_2_2_2_file: undefined,

  checkpoints_testing_2_3_1_document: undefined,
  checkpoints_testing_2_3_1_file: undefined,
  checkpoints_testing_2_3_2_document: undefined,
  checkpoints_testing_2_3_2_file: undefined,

  checkpoints_testing_2_4_file: undefined,
  checkpoints_testing_3_left_image_th: undefined,
  checkpoints_testing_3_left_image_en: undefined,
  checkpoints_testing_3_right_image: undefined,
  checkpoints_testing_3_file: undefined,
  checkpoints_testing_4_image: undefined,
  checkpoints_testing_4_1_file: undefined,
  checkpoints_testing_4_1_document: undefined,
  checkpoints_testing_4_2_file: undefined,
  checkpoints_testing_4_2_file: undefined,
  checkpoints_testing_4_3_document: undefined,
  checkpoints_testing_4_3_document: undefined,
};

const Checkpoints = () => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [content, setContent] = useState(null);
  const [image, setImage] = useState(initImage);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      const {
        data: { data },
      } = await api.getContent();
      setContent(data.content);
      setImage({
        checkpoint_background: data.content["checkpoint_background"],
        checkpoint_image_1: data.content["checkpoint_image_1"],
        checkpoint_image_2: data.content["checkpoint_image_2"],
        checkpoint_image_3: data.content["checkpoint_image_3"],
        checkpoint_image_4: data.content["checkpoint_image_4"],
        checkpoints_testing_1_image:
          data.content["checkpoints_testing_1_image"],
        checkpoints_testing_1_file: data.content["checkpoints_testing_1_file"],
        checkpoints_testing_2_image:
          data.content["checkpoints_testing_2_image"],

        checkpoints_testing_2_1_1_file:
          data.content["checkpoints_testing_2_1_1_file"],
        checkpoints_testing_2_1_1_document:
          data.content["checkpoints_testing_2_1_1_document"],
        checkpoints_testing_2_1_2_file:
          data.content["checkpoints_testing_2_1_2_file"],
        checkpoints_testing_2_1_2_document:
          data.content["checkpoints_testing_2_1_2_document"],
        checkpoints_testing_2_1_3_file:
          data.content["checkpoints_testing_2_1_3_file"],
        checkpoints_testing_2_1_3_document:
          data.content["checkpoints_testing_2_1_3_document"],
        checkpoints_testing_2_1_4_file:
          data.content["checkpoints_testing_2_1_4_file"],
        checkpoints_testing_2_1_4_document:
          data.content["checkpoints_testing_2_1_4_document"],

          checkpoints_testing_2_1_5_file:
          data.content["checkpoints_testing_2_1_5_file"],
        checkpoints_testing_2_1_5_document:
          data.content["checkpoints_testing_2_1_5_document"],

          checkpoints_testing_2_1_6_file:
          data.content["checkpoints_testing_2_1_6_file"],
        checkpoints_testing_2_1_6_document:
          data.content["checkpoints_testing_2_1_6_document"],

          checkpoints_testing_2_1_7_file:
          data.content["checkpoints_testing_2_1_7_file"],
        checkpoints_testing_2_1_7_document:
          data.content["checkpoints_testing_2_1_7_document"],

        checkpoints_testing_2_4_file:
          data.content["checkpoints_testing_2_4_file"],

        checkpoints_testing_2_2_1_file:
          data.content["checkpoints_testing_2_2_1_file"],
        checkpoints_testing_2_2_1_document:
          data.content["checkpoints_testing_2_2_1_document"],
        checkpoints_testing_2_2_2_file:
          data.content["checkpoints_testing_2_2_2_file"],
        checkpoints_testing_2_2_2_document:
          data.content["checkpoints_testing_2_2_2_document"],

        checkpoints_testing_2_3_1_file:
          data.content["checkpoints_testing_2_3_1_file"],
        checkpoints_testing_2_3_1_document:
          data.content["checkpoints_testing_2_3_1_document"],
        checkpoints_testing_2_3_2_file:
          data.content["checkpoints_testing_2_3_2_file"],
        checkpoints_testing_2_3_2_document:
          data.content["checkpoints_testing_2_3_2_document"],

        checkpoints_testing_3_left_image_th:
          data.content["checkpoints_testing_3_left_image_th"],
        checkpoints_testing_3_left_image_en:
          data.content["checkpoints_testing_3_left_image_en"],
        checkpoints_testing_3_right_image:
          data.content["checkpoints_testing_3_right_image"],
        checkpoints_testing_3_file: data.content["checkpoints_testing_3_file"],
        checkpoints_testing_4_image:
          data.content["checkpoints_testing_4_image"],
        checkpoints_testing_4_1_file:
          data.content["checkpoints_testing_4_1_file"],
        checkpoints_testing_4_1_document:
          data.content["checkpoints_testing_4_1_document"],
        checkpoints_testing_4_2_file:
          data.content["checkpoints_testing_4_2_file"],
        checkpoints_testing_4_2_document:
          data.content["checkpoints_testing_4_2_document"],
        checkpoints_testing_4_3_file:
          data.content["checkpoints_testing_4_3_file"],
        checkpoints_testing_4_3_document:
          data.content["checkpoints_testing_4_3_document"],
      });
      form.setFieldsValue(data.content);
    } catch (error) {
      console.log("Error while call init()", error);
    }
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  }

  function handleChange(info, key) {
    if (info.file.status === "done") {
      setImage((prev) => ({ ...prev, [key]: info.file.response.data.url }));
    }
  }

  const onFinish = (values) => {
    update({
      ...values,
      ...image,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  async function update(data) {
    try {
      setIsSubmitting(true);
      const res = await api.updateContent(data);
      message.success("บันทึกเรียบร้อยแล้ว");
    } catch (error) {
      message.success("เกิดข้อผิดพลา ดกรุณาลองใหม่อีกครั้ง");
      console.log("Error while call error()", error);
    }
    setIsSubmitting(false);
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Layout>
      <Form
        form={form}
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="รูปพื้นหลัง" name="checkpoint_background">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) => handleChange(info, "checkpoint_background")}
          >
            {image.checkpoint_background ? (
              <img
                src={image.checkpoint_background}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Divider />

        <Form.Item label="ข้อความ (ไทย)" name="checkpoint_title_1_th">
          <Input />
        </Form.Item>
        <Form.Item label="ข้อความ (อังกฤษ)" name="checkpoint_title_1_en">
          <Input />
        </Form.Item>
        <Form.Item label="รูป" name="checkpoint_image_1">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) => handleChange(info, "checkpoint_image_1")}
          >
            {image.checkpoint_image_1 ? (
              <img
                src={image.checkpoint_image_1}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Divider />
        <Form.Item label="ข้อความ (ไทย)" name="checkpoint_title_2_th">
          <Input />
        </Form.Item>
        <Form.Item label="ข้อความ (อังกฤษ)" name="checkpoint_title_2_en">
          <Input />
        </Form.Item>
        <Form.Item label="รูป" name="checkpoint_image_2">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) => handleChange(info, "checkpoint_image_2")}
          >
            {image.checkpoint_image_2 ? (
              <img
                src={image.checkpoint_image_2}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Divider />
        <Form.Item label="ข้อความ (ไทย)" name="checkpoint_title_3_th">
          <Input />
        </Form.Item>
        <Form.Item label="ข้อความ (อังกฤษ)" name="checkpoint_title_3_en">
          <Input />
        </Form.Item>
        <Form.Item label="รูป" name="checkpoint_image_3">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) => handleChange(info, "checkpoint_image_3")}
          >
            {image.checkpoint_image_3 ? (
              <img
                src={image.checkpoint_image_3}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Divider />
        <Form.Item label="ข้อความ (ไทย)" name="checkpoint_title_4_th">
          <Input />
        </Form.Item>
        <Form.Item label="ข้อความ (อังกฤษ)" name="checkpoint_title_4_en">
          <Input />
        </Form.Item>
        <Form.Item label="รูป" name="checkpoint_image_4">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) => handleChange(info, "checkpoint_image_4")}
          >
            {image.checkpoint_image_4 ? (
              <img
                src={image.checkpoint_image_4}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Divider />

        <Form.Item label="หัวข้อ (ไทย)" name="checkpoints_testing_1_title_th">
          <Input />
        </Form.Item>
        <Form.Item
          label="หัวข้อ (อังกฤษ)"
          name="checkpoints_testing_1_title_en"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ข้อความ (ไทย)"
          name="checkpoints_testing_1_description_th"
        >
          <Input.TextArea rows="4" />
        </Form.Item>
        <Form.Item
          label="ข้อความ (อังกฤษ)"
          name="checkpoints_testing_1_description_en"
        >
          <Input.TextArea rows="4" />
        </Form.Item>
        <Form.Item label="ลิ้งค์ (ไทย)" name="checkpoints_testing_1_link_th">
          <Input />
        </Form.Item>
        <Form.Item label="ลิ้งค์ (อังกฤษ)" name="checkpoints_testing_1_link_en">
          <Input />
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_1_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_1_file")
            }
          >
            {image.checkpoints_testing_1_file ? (
              <img
                src={image.checkpoints_testing_1_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="รูปด้านซ้าย" name="checkpoints_testing_1_image">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_1_image")
            }
          >
            {image.checkpoints_testing_1_image ? (
              <img
                src={image.checkpoints_testing_1_image}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Divider />

        <Form.Item label="หัวข้อ (ไทย)" name="checkpoints_testing_2_title_th">
          <Input />
        </Form.Item>
        <Form.Item
          label="หัวข้อ (อังกฤษ)"
          name="checkpoints_testing_2_title_en"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ข้อความ (ไทย)"
          name="checkpoints_testing_2_description_th"
        >
          <Input.TextArea rows="4" />
        </Form.Item>
        <Form.Item
          label="ข้อความ (อังกฤษ)"
          name="checkpoints_testing_2_description_en"
        >
          <Input.TextArea rows="4" />
        </Form.Item>

        <Form.Item
          label="หัวข้อรอง (ไทย)"
          name="checkpoints_testing_2_subtitle_th"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="หัวข้อรอง (อังกฤษ)"
          name="checkpoints_testing_2_subtitle_en"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="รายละเอียด (ไทย)"
          name="checkpoints_testing_2_meta_th"
        >
          <Input.TextArea rows="4" />
        </Form.Item>
        <Form.Item
          label="รายละเอียด (อังกฤษ)"
          name="checkpoints_testing_2_meta_en"
        >
          <Input.TextArea rows="4" />
        </Form.Item>

        <Form.Item label="รูป" name="checkpoints_testing_2_image">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_image")
            }
          >
            {image.checkpoints_testing_2_image ? (
              <img
                src={image.checkpoints_testing_2_image}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ลิ้งค์ (ไทย)" name="checkpoints_testing_2_1_link_th">
          <Input />
        </Form.Item>
        <Form.Item
          label="ลิ้งค์ (อังกฤษ)"
          name="checkpoints_testing_2_1_link_en"
        >
          <Input />
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_2_1_1_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_1_file")
            }
          >
            {image.checkpoints_testing_2_1_1_file ? (
              <img
                src={image.checkpoints_testing_2_1_1_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_2_1_1_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_1_document")
            }
          >
            {image.checkpoints_testing_2_1_1_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_2_1_2_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_2_file")
            }
          >
            {image.checkpoints_testing_2_1_2_file ? (
              <img
                src={image.checkpoints_testing_2_1_2_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_2_1_2_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_2_document")
            }
          >
            {image.checkpoints_testing_2_1_2_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_2_1_3_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_3_file")
            }
          >
            {image.checkpoints_testing_2_1_3_file ? (
              <img
                src={image.checkpoints_testing_2_1_3_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_2_1_3_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_3_document")
            }
          >
            {image.checkpoints_testing_2_1_3_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_2_1_4_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_4_file")
            }
          >
            {image.checkpoints_testing_2_1_4_file ? (
              <img
                src={image.checkpoints_testing_2_1_4_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_2_1_4_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_4_document")
            }
          >
            {image.checkpoints_testing_2_1_4_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_2_1_5_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_5_file")
            }
          >
            {image.checkpoints_testing_2_1_5_file ? (
              <img
                src={image.checkpoints_testing_2_1_5_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_2_1_5_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_5_document")
            }
          >
            {image.checkpoints_testing_2_1_5_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_2_1_6_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_6_file")
            }
          >
            {image.checkpoints_testing_2_1_6_file ? (
              <img
                src={image.checkpoints_testing_2_1_6_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_2_1_6_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_6_document")
            }
          >
            {image.checkpoints_testing_2_1_6_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_2_1_7_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_7_file")
            }
          >
            {image.checkpoints_testing_2_1_7_file ? (
              <img
                src={image.checkpoints_testing_2_1_7_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_2_1_7_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_1_7_document")
            }
          >
            {image.checkpoints_testing_2_1_7_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ลิ้งค์ (ไทย)" name="checkpoints_testing_2_2_link_th">
          <Input />
        </Form.Item>
        <Form.Item
          label="ลิ้งค์ (อังกฤษ)"
          name="checkpoints_testing_2_2_link_en"
        >
          <Input />
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_2_2_1_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_2_1_file")
            }
          >
            {image.checkpoints_testing_2_2_1_file ? (
              <img
                src={image.checkpoints_testing_2_2_1_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_2_2_1_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_2_1_document")
            }
          >
            {image.checkpoints_testing_2_2_1_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_2_2_2_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_2_2_file")
            }
          >
            {image.checkpoints_testing_2_2_2_file ? (
              <img
                src={image.checkpoints_testing_2_2_2_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_2_2_2_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_2_2_document")
            }
          >
            {image.checkpoints_testing_2_2_2_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ลิ้งค์ (ไทย)" name="checkpoints_testing_2_3_link_th">
          <Input />
        </Form.Item>
        <Form.Item
          label="ลิ้งค์ (อังกฤษ)"
          name="checkpoints_testing_2_3_link_en"
        >
          <Input />
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_2_3_1_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_3_1_file")
            }
          >
            {image.checkpoints_testing_2_3_1_file ? (
              <img
                src={image.checkpoints_testing_2_3_1_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_2_3_1_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_3_1_document")
            }
          >
            {image.checkpoints_testing_2_3_1_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_2_3_2_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_3_2_file")
            }
          >
            {image.checkpoints_testing_2_3_2_file ? (
              <img
                src={image.checkpoints_testing_2_3_2_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_2_3_2_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_3_2_document")
            }
          >
            {image.checkpoints_testing_2_3_2_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ลิ้งค์ (ไทย)" name="checkpoints_testing_2_4_link_th">
          <Input />
        </Form.Item>
        <Form.Item
          label="ลิ้งค์ (อังกฤษ)"
          name="checkpoints_testing_2_4_link_en"
        >
          <Input />
        </Form.Item>

        <Form.Item label="รูป" name="checkpoints_testing_2_4_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_2_4_file")
            }
          >
            {image.checkpoints_testing_2_4_file ? (
              <img
                src={image.checkpoints_testing_2_4_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Divider />

        <Form.Item label="หัวข้อ (ไทย)" name="checkpoints_testing_3_title_th">
          <Input />
        </Form.Item>
        <Form.Item
          label="หัวข้อ (อังกฤษ)"
          name="checkpoints_testing_3_title_en"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ข้อความ (ไทย)"
          name="checkpoints_testing_3_description_th"
        >
          <Input.TextArea rows="4" />
        </Form.Item>
        <Form.Item
          label="ข้อความ (อังกฤษ)"
          name="checkpoints_testing_3_description_en"
        >
          <Input.TextArea rows="4" />
        </Form.Item>
        <Form.Item label="ลิ้งค์ (ไทย)" name="checkpoints_testing_3_link_th">
          <Input />
        </Form.Item>
        <Form.Item label="ลิ้งค์ (อังกฤษ)" name="checkpoints_testing_3_link_en">
          <Input />
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_3_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_3_file")
            }
          >
            {image.checkpoints_testing_3_file ? (
              <img
                src={image.checkpoints_testing_3_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="รูปด้านซ้าย (ไทย)"
          name="checkpoints_testing_3_left_image_th"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_3_left_image_th")
            }
          >
            {image.checkpoints_testing_3_left_image_th ? (
              <img
                src={image.checkpoints_testing_3_left_image_th}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="รูปด้านซ้าย (อังกฤษ)"
          name="checkpoints_testing_3_left_image_en"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_3_left_image_en")
            }
          >
            {image.checkpoints_testing_3_left_image_en ? (
              <img
                src={image.checkpoints_testing_3_left_image_en}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="รูปด้านขวา" name="checkpoints_testing_3_right_image">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_3_right_image")
            }
          >
            {image.checkpoints_testing_3_right_image ? (
              <img
                src={image.checkpoints_testing_3_right_image}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Divider />

        <Form.Item label="หัวข้อ (ไทย)" name="checkpoints_testing_4_title_th">
          <Input />
        </Form.Item>
        <Form.Item
          label="หัวข้อ (อังกฤษ)"
          name="checkpoints_testing_4_title_en"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ข้อความ (ไทย)"
          name="checkpoints_testing_4_description_th"
        >
          <Input.TextArea rows="4" />
        </Form.Item>
        <Form.Item
          label="ข้อความ (อังกฤษ)"
          name="checkpoints_testing_4_description_en"
        >
          <Input.TextArea rows="4" />
        </Form.Item>

        <Form.Item label="รูป" name="checkpoints_testing_4_image">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_4_image")
            }
          >
            {image.checkpoints_testing_4_image ? (
              <img
                src={image.checkpoints_testing_4_image}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ลิ้งค์ (ไทย)" name="checkpoints_testing_4_1_link_th">
          <Input />
        </Form.Item>
        <Form.Item
          label="ลิ้งค์ (อังกฤษ)"
          name="checkpoints_testing_4_1_link_en"
        >
          <Input />
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_4_1_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_4_1_file")
            }
          >
            {image.checkpoints_testing_4_1_file ? (
              <img
                src={image.checkpoints_testing_4_1_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_4_1_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_4_1_document")
            }
          >
            {image.checkpoints_testing_4_1_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ลิ้งค์ (ไทย)" name="checkpoints_testing_4_2_link_th">
          <Input />
        </Form.Item>

        <Form.Item
          label="ลิ้งค์ (อังกฤษ)"
          name="checkpoints_testing_4_2_link_en"
        >
          <Input />
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_4_2_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_4_2_file")
            }
          >
            {image.checkpoints_testing_4_2_file ? (
              <img
                src={image.checkpoints_testing_4_2_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_4_2_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_4_2_document")
            }
          >
            {image.checkpoints_testing_4_2_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label="ลิ้งค์ (ไทย)" name="checkpoints_testing_4_3_link_th">
          <Input />
        </Form.Item>
        <Form.Item
          label="ลิ้งค์ (อังกฤษ)"
          name="checkpoints_testing_4_3_link_en"
        >
          <Input />
        </Form.Item>

        <Form.Item label="ไฟล์" name="checkpoints_testing_4_3_file">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_4_3_file")
            }
          >
            {image.checkpoints_testing_4_3_file ? (
              <img
                src={image.checkpoints_testing_4_3_file}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ไฟล์สำหรับดาวน์โหลด"
          name="checkpoints_testing_4_3_document"
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={api.upload}
            onChange={(info) =>
              handleChange(info, "checkpoints_testing_4_3_document")
            }
          >
            {image.checkpoints_testing_4_3_document ? (
              <FileOutlined />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button htmlType="button">ยกเลิก</Button>
          <Button type="primary" htmlType="submit">
            บันทึก
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default Checkpoints;
