import React, { useEffect, useState } from "react";
import { Layout, Menu, Breadcrumb } from "antd";
import { Link, useLocation, useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import * as api from "../../api";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const MainLayout = ({ children }) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setSelectedKeys(location.pathname);
    validate();
  }, [location.pathname]);

  async function logout() {
    await Cookie.remove("access_token");
    history.push("/admin/login");
  }

  async function validate() {
    try {
      const accessToken = Cookie.get("access_token");
      if (!accessToken) {
        history.push("/admin/login");
        return;
      }
    } catch (error) {
      console.log("Error while call validate()", error);
    }
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider>
        <div className="logo">
          <img src="/images/logo.png" width="128" />
        </div>
        <Menu
          selectedKeys={selectedKeys}
          defaultSelectedKeys={["/admin/product-information"]}
          mode="inline"
        >
          <Menu.Item key="/admin/product-information">
            <Link to="/admin/product-information">ที่มาของ Besuto12</Link>
          </Menu.Item>
          <Menu.Item key="/admin/checkpoints">
            <Link to="/admin/checkpoints">คุณสมบัติเด่น</Link>
          </Menu.Item>
          <Menu.Item key="/admin/qualification">
            <Link to="/admin/qualification">คุณสมบัติอื่นๆ</Link>
          </Menu.Item>
          <Menu.Item key="/admin/how-to">
            <Link to="/admin/how-to">วิธีใช้</Link>
          </Menu.Item>
          <Menu.Item key="/admin/faqs">
            <Link to="/admin/faqs">คำถามที่พบบ่อย</Link>
          </Menu.Item>
          <Menu.Item key="/admin/awards">
            <Link to="/admin/awards">รางวัล</Link>
          </Menu.Item>
          <Menu.Item key="/admin/stores">
            <Link to="/admin/stores">สถานที่จำหน่าย</Link>
          </Menu.Item>
          <Menu.Item key="/admin/contact">
            <Link to="/admin/contact">ติดต่อเรา</Link>
          </Menu.Item>
          <Menu.Item onClick={logout}>
            ออกจากระบบ
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "16px" }}>
          {/* <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb> */}
          <div className="site-layout-background">{children}</div>
        </Content>
        <Footer style={{ textAlign: "center" }}>Besuto12 ©2020</Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
